import { useParams } from "react-router-dom";
import ExtendProduct from "./ExtendProduct";
import { Box } from "@mui/material";

export const ShowProduct = () => {
  const { id } = useParams();

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <ExtendProduct row={{ id, onUpdate: () => {}, onDelete: () => {} }} />;
    </Box>
  );
};
