import { useTheme, Box, Button } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import SingleFileUpload from '../upload/SingleFileUpload';
import { DBUploadData, useConfig } from '../../api/api';
import { tokens } from '../../global/theme/tokens';
import Feedback from '../special/Feedback';
import ExoForm from '../exo/ExoForm';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import findDifferences from '../exo/findDifferencesInDataCollection';
import uuid from 'react-uuid';

const NewReceipt = ({ onClose, receipt, debug, onCreate, open }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const exoFormRef = useRef(null);
  const singeUploadRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const config = useConfig();

  const defaultValues = {
    origin: 'domestic',
  };
  const [usedValues, setUsedValues] = useState(defaultValues);
  useEffect(() => {
    setUsedValues(receipt || defaultValues);
  }, [receipt, open]);

  function handleSave(data) {
    var rawData = receipt && receipt.id ? findDifferences(receipt, data) : data;
    const formData = new FormData();

    if (file && !file.id) {
      if (Object.keys(file).length) {
        formData.append(`file`, file);
      } else {
        formData.append(`file`, null);
      }
    }
    if (rawData.reverseCharge)
      formData.append(`reverseCharge`, rawData.reverseCharge);
    if (rawData.title) formData.append(`title`, rawData.title);
    if (rawData.receiptDate)
      formData.append(`receiptDate`, rawData.receiptDate);
    if (rawData.receiptNr) formData.append(`receiptNr`, rawData.receiptNr);
    if (rawData.dueDate) formData.append(`dueDate`, rawData.dueDate);
    formData.append(`origin`, rawData.origin || defaultValues.origin);
    if (rawData.positions)
      formData.append(`positions`, JSON.stringify(rawData.positions));
    if (rawData.transactions)
      formData.append(`transactions`, JSON.stringify(rawData.transactions));

    if (receipt && receipt.id) formData.append('_method', 'PATCH');

    const path = receipt && receipt.id ? `receipts/${receipt.id}` : 'receipts';
    setIsLoading(true);
    DBUploadData({
      config,
      path,
      formData: formData,
      onResponse: handleResponse,
    });
  }

  function handleResponse(response) {
    setIsLoading(false);
    if (response.status === 200) {
      onCreate(response.data.data);
    }
    if (response.status === 201) {
      onCreate(response.data.data);
      setAlertState({
        alertOpen: true,
        alertText: response.data.message,
        alertType: 'success',
      });
      exoFormRef.current.reset();
      singeUploadRef.current.reset();
    } else {
      setAlertState({
        alertOpen: true,
        alertText: response.response.data.message,
        alertType: 'error',
      });
    }
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: 'info',
    alertText: 'test',
  });

  //--------------------------

  const fields = [
    {
      label: `${t('Transactions')} (${t('optional')})`,
      type: 'transactionArray',
      key: 'transactions',
      colspan: 2,
    },
    {
      label: t('Title'),
      type: 'text',
      key: 'title',
    },
    {
      label: t('Receipt Number'),
      type: 'text',
      key: 'receiptNr',
    },
    {
      label: t('Receipt Date'),
      type: 'date',
      key: 'receiptDate',
    },
    {
      label: `${t('Due Date')} (${t('optional')})`,
      type: 'date',
      key: 'dueDate',
    },
    {
      label: t('Reverse Charge Bill'),
      type: 'checkbox',
      key: 'reverseCharge',
    },
    {
      label: t('Origin'),
      type: 'customSelect',
      key: 'origin',
      options: [
        {
          label: t('Domestic'),
          value: 'domestic',
        },
        {
          label: t('EU'),
          value: 'eu',
        },
        {
          label: t('Foreign'),
          value: 'foreign',
        },
      ],
    },
    {
      label: t('Receipt Position'),
      key: 'positions',
      type: 'receiptPositions',
      colspan: 2,
    },
  ];

  // custom startup
  let oldTitle;
  let oldAmount = 0;
  let oldTransactions = null;

  function handleChange(data, isValid) {
    const { price, transactions, title } = data;

    // Amount
    if (
      (oldAmount !== 0 || !receipt) &&
      (!price || price === oldAmount) &&
      transactions
    ) {
      const hasTransactionChanges =
        !oldTransactions || hasDifferences(oldTransactions, transactions);

      if (hasTransactionChanges) {
        const newAmount = transactions.reduce(
          (total, element) =>
            total +
            parseFloat(element.openAmount) +
            parseFloat(element.assingedAmount || 0),
          0
        );
        oldAmount = newAmount * -1;
        oldTransactions = transactions;

        exoFormRef.current.updateDataCollection({
          receipt: { ...data.receipt, amount: newAmount * -1 },
        });
        return;
      }
    }

    // Title
    if (oldTitle !== title) {
      exoFormRef.current.updateDataCollection({
        receipt: { ...data.receipt, position: title },
      });
      oldTitle = title;
      return;
    }
  }

  const hasDifferences = (array1, array2) => {
    // Check if they have the same length first
    if (array1.length !== array2.length) return true;

    // Create a map for array2 for easy lookup by id
    const map2 = new Map(array2.map((item) => [item.id, item]));

    // Compare objects from array1 with those in array2
    for (const item1 of array1) {
      const item2 = map2.get(item1.id);
      // If no corresponding object in array2 or objects are different
      if (!item2 || JSON.stringify(item1) !== JSON.stringify(item2)) {
        return true;
      }
    }

    return false;
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('Title is required')),
    receiptDate: Yup.string().required(t('Start Date is required')),
    origin: Yup.string().required(t('Origin is required')),
    receiptNr: Yup.string().required(t('Receipt Ner is required')),
    positions: Yup.array()
      .of(
        Yup.object().shape({
          amount: Yup.string().required(t('Amount is required')),
          position: Yup.string().required(t('Position is required')),
          taxAssignment: Yup.object().shape({
            id: Yup.number().required(t('Tax Assignment is required')),
          }),
          taxRate: Yup.number().required(t('Tax Rate is required')),
          costCenter: Yup.object().shape({
            id: Yup.string().required(t('Cost Center is required')),
          }),
        })
      )
      .required(t('Is required')),
  });

  // preload file
  useEffect(() => {
    if (receipt && receipt.file) {
      const newFileData = {
        id: receipt.file.id,
        lastModified: 0,
        name: receipt.file.title,
        path: receipt.file.temporaryUrl,
        size: receipt.file.fileSize,
        type: receipt.file.mimeType,
        webkitRelativePath: '',
      };
      setFile(newFileData);
      singeUploadRef.current.setFileData(newFileData);
    } else {
      singeUploadRef.current.reset();
    }
  }, [receipt]);

  // ai accounting
  function handleFileChange(data) {
    if (
      (data && data.name) ||
      (receipt && data.name === receipt.name && data.size === receipt.size)
    ) {
      handleAiAccounting(data);
    }
    setFile(data);
  }

  const [aiIsProcessing, setAiIsProcessing] = useState(false);

  function handleAiAccounting(data) {
    if (!data.name) return;

    const formData = new FormData();
    formData.append(`file`, data);
    setAiIsProcessing(true);
    DBUploadData({
      config,
      path: 'ai/accounting',
      formData: formData,
      onResponse: handleAiResponse,
      onError: () => setAiIsProcessing(false),
    });
  }

  function handleAiResponse(res) {
    setAiIsProcessing(false);
    const data = res.data.data;
    data.positions.forEach((element) => {
      element.id = uuid();
    });

    exoFormRef.current.updateDataCollection(data);
  }

  // handle PDF preview width
  const previewContainerRef = useRef(null);
  const [previewWidth, setPreviewWidth] = useState('100%');
  const handleResize = () => {
    // Update containerWidth when the container is resized
    if (previewContainerRef.current) {
      const containerWidth = previewContainerRef.current.offsetWidth;
      setPreviewWidth(containerWidth);
    }
  };
  useEffect(() => {
    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [previewContainerRef]);

  return (
    <Box className="w-full h-full overflow-y-auto flex flex-col-reverse lg:flex-row gap-2 relative px-2 py-4 lg:px-2 lg:py-2 mx-auto">
      <Box
        ref={previewContainerRef}
        className="col-span-1 lg:col-span-2 w-full py-10 lg:max-w-[calc(100vw-550px-40px)]  sticky top-0"
      >
        <Box
          className="flex flex-col items-center justify-center overflow-x-hidden overflow-y-auto h-96 lg:h-full w-full relative"
          sx={{
            minHeight: '300px',
            width: previewWidth,
          }}
        >
          <SingleFileUpload
            debug={debug}
            ref={singeUploadRef}
            setFile={handleFileChange}
            uploadedMedia={file}
            aiIsRunning={aiIsProcessing}
          />
        </Box>
      </Box>
      <Box className="col-span-1 lg:col-span-3 flex flex-col gap-4 p-0 lg:p-1 rounded-lg max-w-full min-w-[unset] w-full lg:min-w-[550px] lg:max-w-[550px]">
        <ExoForm
          param={{ file }}
          className="grid grid-cols-2 gap-4"
          validationSchema={validationSchema}
          ref={exoFormRef}
          startDataCollection={usedValues}
          noDifferences
          fields={fields}
          onChange={handleChange}
          onSubmit={handleSave}
          header={t('New Receipt')}
          onCancle={onClose}
          useStartDataOnReset
          debug={debug}
          submitText={receipt ? t('Update') : t('Create')}
        />
      </Box>

      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

export default NewReceipt;
