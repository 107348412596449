import { useParams } from "react-router-dom";
import ExtendDeliveryNote from "./extendDeliveryNote";
import { Box } from "@mui/material";

export const ShowDeliveryNote = () => {
  const { id } = useParams();

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <ExtendDeliveryNote
        row={{ id, onUpdate: () => {}, onDelete: () => {} }}
      />
    </Box>
  );
};
