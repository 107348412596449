import { useState, useRef, useEffect } from "react";
import { useTheme, Box, Button, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import RichTextEditor from "./RichTextEditor";
import VarList from "./presetEditor/VarList";
import TypeText from "../form/form-fields/TypeText";
import ExoTabs from "../exo/ExoTabs";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { EditorState, ContentState, Modifier } from "draft-js";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import ExoPositions from "../exo/ExoPositions";
import { DBRequest, useConfig } from "../../api/api";
import Loader from "../special/Loader";
import { PositionDescription } from "../exo/positions/collapse-modules/PositionDescription";
import { PositionNumber } from "../exo/positions/collapse-modules/PositionNumber";
import { PositionText } from "../exo/positions/collapse-modules/PositionText";

const PresetEditor = ({
  onClose,
  onSubmit,
  startDataCollection,
  debug,
  type = "contract", // employeeContract or noCategory,
  validationSchema,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();

  const positionsRef = useRef(null);
  const additionalPositionsRef = useRef(null);
  const richTextEditorRef = useRef(null);
  const [dbStartData, setDbStartData] = useState(null);
  const [dataCollection, setDataCollection] = useState({
    category: type,
    positions: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  function handleSubmit() {
    onSubmit(dataCollection);
  }

  function handleUpdateDataCollection(key, value) {
    setDataCollection({ ...dataCollection, [key]: value });
  }

  useEffect(() => {
    // update startvalues if changed
    if (startDataCollection && startDataCollection.id) {
      DBRequest({
        config,
        path: `contractpresets/${startDataCollection.id}`,
        onLoading: setIsLoading,
        onResponse: handleStartData,
      });
    } else {
      setIsLoading(false);
    }
  }, [startDataCollection, config]);

  function handleStartData(res) {
    setDbStartData(res.data);
    setDataCollection(res.data);
  }

  // tabs
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [currentField, setCurrentField] = useState(null);

  const handleRTEClickVar = (text) => {
    if (!richTextEditorRef.current) return;

    // Get the current editor state
    const editorState = richTextEditorRef.current.props.editorState;

    // Get the current selection
    const selection = editorState.getSelection();

    // Create a new ContentState with the pasted text
    const newContentState = ContentState.createFromText(text);

    // Create a new ContentState with the pasted text inserted at the current selection
    const newContentStateWithPaste = Modifier.insertText(
      editorState.getCurrentContent(),
      selection,
      text
    );

    // Create a new EditorState with the pasted text inserted
    const newEditorState = EditorState.push(
      editorState,
      newContentStateWithPaste,
      "insert-characters"
    );

    // Update the editor state
    richTextEditorRef.current.props.onChange(newEditorState);

    // Set the selection to the end of the pasted text
    const newSelection = selection.merge({
      anchorOffset: selection.getAnchorOffset() + text.length,
      focusOffset: selection.getAnchorOffset() + text.length,
    });
    const newEditorStateWithSelection = EditorState.forceSelection(
      newEditorState,
      newSelection
    );
    richTextEditorRef.current.props.onChange(newEditorStateWithSelection);
  };

  function handlePositionChange(positions) {
    setDataCollection({ ...dataCollection, positions: [...positions] });
  }
  function handleAdditionalServicesChange(positions) {
    setDataCollection({
      ...dataCollection,
      additionalServices: [...positions],
    });
  }
  function handlePositionsClickVar(textToPaste, keyProp) {
    const positions = dataCollection[keyProp];
    const textFieldValue = positions[currentField.index][currentField.key];
    if (currentField.ref.current) {
      const inputElement = currentField.ref.current.querySelector("input");
      if (inputElement) {
        const start = inputElement.selectionStart;
        const end = inputElement.selectionEnd;
        const currentValue = textFieldValue ? "" + textFieldValue : "";

        const newText =
          currentValue.substring(0, start) +
          textToPaste +
          currentValue.substring(end);

        const newPositions = positions.map((pos, index) => {
          if (index === currentField.index) {
            return { ...pos, [currentField.key]: newText };
          } else {
            return pos;
          }
        });
        // Update the dataCollection state with the new text value
        setDataCollection({ ...dataCollection, [keyProp]: newPositions });
        if (keyProp === "positions") {
          positionsRef.current.setItems(newPositions);
        }
        if (keyProp === "additionalServices")
          additionalPositionsRef.current.setItems(newPositions);
      }
    }
  }

  function handlePositionFocus(dataPosition) {
    setCurrentField(dataPosition);
  }

  const tabs = [
    {
      label: t("Contract"),
      content: (
        <RichTextEditor
          startHTML={(dbStartData && dbStartData.body) || ""}
          setHTML={(newBody) => handleUpdateDataCollection("body", newBody)}
          editorRef={richTextEditorRef}
        />
      ),
      onVarClick: handleRTEClickVar,
    },
    {
      label: t("Invoice Positions"),
      content: (
        <Box className="w-full">
          <ExoPositions
            emptyStart
            ref={positionsRef}
            startPositions={dbStartData ? dbStartData.positions : []}
            descriptionVars={dataCollection}
            onPositionChange={handlePositionChange}
            onFocus={handlePositionFocus}
            collapseModules={[
              {
                component: PositionDescription,
                key: "description",
                label: "Description",
              },
              {
                component: PositionNumber,
                key: "taxRate",
                label: "Tax Rate",
                unit: "%",
                value: 19,
                decimalPlaces: 0,
              },
              {
                component: PositionText,
                key: "unit",
                label: "Unit",
              },
            ]}
            columns={[
              {
                text: t("Position"),
                width: "80px",
                align: "left",
                key: "position",
                className: "monospace justify-center",
                number: true,
                decimalPlaces: 2,
                editable: false,
                hidden: false,
                default: 1,
              },
              {
                text: t("Description"),
                width: "auto",
                align: "left",
                key: "name",
                editable: true,
                hidden: false,
                default: "",
              },
              {
                text: t("Amount per Month"),
                width: "180px",
                align: "center",
                key: "amount",
                className: "monospace",
                editable: true,
                hidden: false,
                default: 0,
              },
              {
                text: t("Price"),
                width: "120px",
                align: "right",
                key: "price",
                className: "monospace",
                unit: " €",
                editable: true,
                hidden: false,
                default: 0,
              },
              {
                key: "id",
                hidden: true,
                default: 0,
              },
              {
                key: "description",
                hidden: true,
                default: "",
              },
              {
                key: "positionableId",
                hidden: true,
                default: null,
              },
              {
                key: "positionableType",
                hidden: true,
                default: "custom",
              },
            ]}
            showVars
            hideSum
          />
        </Box>
      ),
      onVarClick: (e) => handlePositionsClickVar(e, "positions"),
    },
    {
      label: t("Additional Services"),
      content: (
        <Box className="w-full">
          <ExoPositions
            debug
            emptyStart
            ref={additionalPositionsRef}
            startPositions={dbStartData ? dbStartData.additionalServices : []}
            descriptionVars={dataCollection}
            onPositionChange={handleAdditionalServicesChange}
            onFocus={handlePositionFocus}
            showVars
            hideProducts
            hideServices
            hideSum
            collapseModules={null}
            columns={[
              {
                text: "",
                width: "20px",
                align: "left",
                key: "drag",
                className: "justify-start",
                hidden: false,
                custom: (element, unit) => <DragHandleIcon />,
              },
              {
                text: t("Task Title"),
                width: "200px",
                align: "left",
                key: "taskTitle",
                editable: true,
                hidden: false,
                default: "",
              },
              {
                text: t("Description"),
                width: "auto",
                align: "left",
                key: "name",
                editable: true,
                hidden: false,
                default: "",
              },
              {
                text: t("Tax Rate"),
                width: "100px",
                align: "right",
                key: "taxRate",
                className: "monospace",
                unit: "%",
                editable: true,
                hidden: false,
                default: "19",
                number: true,
                decimalPlaces: 0,
              },
              {
                text: t("Monthly Limit"),
                width: "150px",
                align: "right",
                key: "monthlyLimit",
                className: "monospace",
                unit: " €",
                editable: true,
                hidden: false,
                default: 0,
              },
              {
                text: t("Price Per Hour"),
                width: "120px",
                align: "right",
                key: "pricePerHour",
                className: "monospace",
                unit: " €",
                editable: true,
                hidden: false,
                default: 0,
              },
              {
                key: "description",
                hidden: true,
                default: "",
              },
              {
                key: "positionableId",
                hidden: true,
                default: null,
              },
              {
                key: "positionableType",
                hidden: true,
                default: "custom",
              },
            ]}
          />
        </Box>
      ),
      onVarClick: (e) => handlePositionsClickVar(e, "additionalServices"),
    },
  ];

  // validation
  const [valid, setValid] = useState(false);
  const validateDataCollection = async () => {
    const usedValidationSchema = validationSchema || validationSchemaMap[type];
    if (!usedValidationSchema) {
      setValid(true);
      return;
    }
    try {
      await usedValidationSchema.validate(dataCollection);
      // validation passed for the specific field
      setValid(true);
    } catch (err) {
      // validation failed for the specific field
      if (true) console.log("NOT VALID", err);
      setValid(false);
    }
  };
  useEffect(() => {
    validateDataCollection();
  }, [dataCollection]);

  return (
    <Box
      className="w-full h-full p-2 flex flex-col relative gap-2"
      sx={{ width: "100%", maxWidth: "100%" }}
    >
      <Loader active={isLoading} />
      {debug && (
        <Button
          variant="contained"
          color="warning"
          onClick={() => console.log(dataCollection)}
        >
          LOG
        </Button>
      )}

      <TypeText
        dataCollection={dataCollection}
        field={{ label: t("Preset Title"), key: "title" }}
        updateValidationOnChange={handleUpdateDataCollection}
      />
      <Box className="w-full h-full rounded-lg flex flex-row gap-2 relative overflow-hidden">
        {!isLoading && (
          <VarList
            type={type}
            dataCollection={dbStartData}
            label={t("Contract Variables")}
            onVarClick={tabs[currentTabIndex].onVarClick}
            onChange={handleUpdateDataCollection}
          />
        )}
        {type === "contract" && (
          <ExoTabs tabs={tabs} onChange={setCurrentTabIndex} />
        )}
        {type === "employeeContract" && (
          <RichTextEditor
            fontSizeFactor={1.3}
            startHTML={(startDataCollection && startDataCollection.body) || ""}
            setHTML={(newBody) => handleUpdateDataCollection("body", newBody)}
            editorRef={richTextEditorRef}
          />
        )}
      </Box>
      <Box className="w-full flex justify-between mt-2">
        <Button color="error" onClick={onClose}>
          {t("Cancle")}
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={handleSubmit}
          disabled={!valid}
        >
          {startDataCollection && startDataCollection.id
            ? t("Update")
            : t("Create")}
        </Button>
      </Box>
    </Box>
  );
};

const validationSchemaMap = {
  contract: Yup.object().shape({
    title: Yup.string().required(),
    body: Yup.string().required(),
    positions: Yup.array().of(
      Yup.object().shape({
        amount: Yup.string().required(),
        name: Yup.string().required(),
        price: Yup.string().required(),
        taxRate: Yup.string().required(),
      })
    ),
    additionalServices: Yup.array().of(
      Yup.object().shape({
        monthlyLimit: Yup.string().required(),
        name: Yup.string().required(),
        pricePerHour: Yup.string().required(),
        taxRate: Yup.string().required(),
        taskTitle: Yup.string().required(),
      })
    ),
  }),

  employeeContract: Yup.object().shape({
    title: Yup.string().required(),
    body: Yup.string().required(),
  }),
};

export default PresetEditor;
