import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NoDataAnimation from '../../../components/animation/NoDataAnimation';

export const EmployeeLinzenses = ({ employeeInfo }) => {
  const { t } = useTranslation();
  const licenses = employeeInfo.accessFeatures;

  if (!licenses || !licenses.length)
    return (
      <Box className="w-full h-full flex justify-center items-center">
        <NoDataAnimation />
      </Box>
    );
  return (
    <Box className="flex flex-col w-full h-full gap-2 py-4 overflow-y-auto">
      {licenses?.map((obj) => (
        <Box className="flex flex-col w-full">
          <Typography fontWeight={500}>{t(obj.label)}</Typography>

          <Typography textAlign={'left'} className="opacity-70" fontSize={12}>
            {t(obj.description)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
