import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DateLabel from '../../components/label/DateLabel';

const DocumentHead = ({ document, ownCompany }) => {
  const { t } = useTranslation();
  return (
    <Box
      className={' pb-4'}
      sx={{
        '& td': {
          padding: '2px',
        },
      }}
    >
      <Typography
        variant="h3"
        className="flex flex-col-reverse sm:flex-row justify-between border-b-2 w-full gap-2"
        sx={{ paddingBottom: '20px!important' }}
      >
        {document.title}
      </Typography>

      <Box className="flex flex-col gap-5 sm:gap-1 sm:flex-row w-full justify-between pt-4">
        <Box>
          <Typography className="opacity-60" fontSize={'10px'} fontWeight={500}>
            {ownCompany.address &&
              ownCompany.companyName +
                ' ' +
                ownCompany.address.street +
                ' ' +
                ownCompany.address.houseNumber +
                ', ' +
                ownCompany.address.zipCode +
                ' ' +
                ownCompany.address.city}
          </Typography>
          <Typography variant="p">
            {document.recipientType === 'company' && (
              <RecipientCompany recipient={document.recipient} />
            )}
            {document.recipientType === 'contact' && (
              <RecipientContact recipient={document.recipient} />
            )}
          </Typography>
        </Box>

        <table>
          <tbody>
            <tr>
              <td>
                <b>{t('Offer Nr.')}</b>
              </td>
              <td>
                <b>{document.nr}</b>
              </td>
            </tr>
            <tr>
              <td>{t('Date')}</td>
              <td>
                <DateLabel date={document.date} />
              </td>
            </tr>
            <tr>
              <td style={{ padding: '2px 10px 2px 2px' }}>
                {t('Contact Person')}
              </td>
              <td>
                {document.contactPerson.salutation +
                  ' ' +
                  document.contactPerson.firstName +
                  ' ' +
                  document.contactPerson.lastName}
              </td>
            </tr>
            <tr>
              <td>{t('Phone')}</td>
              <td>{document.contactPerson.phone}</td>
            </tr>
            <tr>
              <td>{t('E-Mail')}</td>
              <td>
                <a href={'mailto:' + document.contactPerson.email}>
                  {document.contactPerson.email}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

const RecipientCompany = ({ recipient }) => {
  return (
    <Box sx={{ paddingLeft: '5px!important' }}>
      <p>{recipient.name}</p>
      <p>
        {recipient.address.street} {recipient.address.houseNumber}
      </p>
      <p>
        {recipient.address.zipCode} {recipient.address.city}
      </p>
      <p>{recipient.addressAddition}</p>
    </Box>
  );
};
const RecipientContact = ({ recipient }) => {
  return (
    <Box sx={{ paddingLeft: '5px!important' }}>
      <p>
        {recipient.salutation} {recipient.firstName} {recipient.lastName}
      </p>
      <p>
        {recipient.address.street} {recipient.address.houseNumber}
      </p>
      <p>
        {recipient.address.zipCode} {recipient.address.city}
      </p>
      <p>{recipient.addressAddition}</p>
    </Box>
  );
};

export default DocumentHead;
