import ExoStepper, { FinalPage } from '../exo/ExoStepper';
import { useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import { API_POST, DBRequest, useConfig } from '../../api/api';
import StepRecipient from '../steps/StepRecipient';
import StepContractTemplate from '../steps/StepContractTemplate';
import StepContractVars from '../steps/StepContractVars';
import { useTranslation } from 'react-i18next';
import ExoDialog from '../exo/ExoDialog';
import StepDelivery from '../steps/StepDelivery';
import StepUser from '../steps/StepUser';
import { StepTemplate } from '../steps/StepTemplate';

const NewContract = ({ contracts, setContracts, onClose, open, startData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const steps = [
    {
      label: t('Contract Template'),
      content: ({
        dataCollection,
        updateValidation,
        updateDataCollection,
        activeStep,
        setActiveStep,
      }) => (
        <StepContractTemplate
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: t('Contract Info'),
      content: ({
        dataCollection,
        updateValidation,
        updateDataCollection,
        activeStep,
        setActiveStep,
      }) => (
        <StepContractVars
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: t('Recipient'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepRecipient
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t('Template'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepTemplate
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          templateType="contract"
        />
      ),
    },
    {
      label: t('Invoice Template'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepTemplate
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          templateType="invoice"
          templateKey="invoiceTemplateId"
        />
      ),
    },
    {
      label: t('Contact Person'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepUser
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t('Delivery'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepDelivery
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
  ];
  const config = useConfig();
  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);
      DBRequest({
        config,
        path: 'contracts',
        method: API_POST(dataCollection),
        onResponse: handleResponse,
        onLoading: setIsLoading,
        onError: handleError,
      });
    }, // your operation with the data
    content: (response) => (
      <FinalPage response={response} data={contracts} setData={setContracts} />
    ), // component with final operations and the feedback
  };

  return (
    <ExoDialog open={open}>
      <ExoStepper
        label={
          startData && startData.id ? t('Edit Contract') : t('New Contract')
        }
        draftType={'contract'}
        onClose={onClose}
        data={steps}
        finalStep={finalStep}
        startDataCollection={{}}
      />
    </ExoDialog>
  );
};

export default NewContract;
