import { Box, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { tokens } from '../../global/theme/tokens';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const demoElements = [
  {
    title: 'demo',
    content: 'demoContent',
  },
];

const ExoAccordion = ({ elements = demoElements, onSelect = () => {} }) => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    onSelect(elements[expanded]);
  };

  return (
    <Box className="flex flex-col">
      {elements.map((element, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary>
            <Typography>{element.title}</Typography>
          </AccordionSummary>
          <MuiAccordionDetails>{element.content}</MuiAccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

const Accordion = (props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
);

const AccordionSummary = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <MuiAccordionSummary
      className=" p-2 rounded-lg"
      sx={{
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      }}
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  );
};

export default ExoAccordion;
