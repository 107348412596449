import { Box, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { tokens } from "../../global/theme/tokens";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";

import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ExoTable from "../../components/exo/ExoTable";
import { OfferStatus } from "../../components/special/Status";
import ExtendOffer from "./ExtendOffer";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadSigendOffer from "./UploadSignedOffer";
import Header from "../../components/special/Header";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import NewOffer from "../../components/new/NewOffer";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../components/label/MoneyLabel";
import useFetch from "../../api/useFetch";
import { useNavigate } from "react-router-dom";
import { recipientTypeMap } from "../../components/routing/routingMaps";

const Offers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { apiData, setApiData } = useFetch("offers");

  // table
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <OfferStatus number={row.status}></OfferStatus>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Title"),
      key: "title",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      onClick: (row) => navigate(`/offers/${row.id}`),
    },
    {
      header: t("Client"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      className: "w-full md:min-w-[270px]",
      cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) =>
        navigate(`/${recipientTypeMap[row.recipientType]}/${row.recipient.id}`),
    },
    {
      header: t("Sum"),
      key: "volume",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      cell: ({ row }) => <MoneyLabel money={row.volume} />,
      sortable: true, // enable sort function for column
    },
  ];

  // new offer
  const [openNew, setOpenNew] = useState(false);
  const handleClickNewOffer = (event, reason) => {
    setOpenNew(!openNew);
  };

  // upload singed offer
  const [openUploadSignedOffer, setOpenUploadSignedOffer] = useState(false);

  return (
    <>
      <Header title={t("Offers")} />

      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Offer")}
          icon={<NoteAddIcon />}
          onClick={handleClickNewOffer}
        />
        <ExoTableToolbarItem
          text={t("Upload Signed Offer")}
          icon={<UploadFileIcon />}
          onClick={() => setOpenUploadSignedOffer(true)}
        />
      </ExoTableToolbar>

      <ExoTable
        data={apiData}
        isLoading={!Boolean(apiData)}
        columns={Columns}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendOffer row={row} onUpdate={onUpdate} onDelete={onDelete} />
        )}
      />

      <NewOffer
        offers={apiData}
        setOffers={setApiData}
        onClose={() => setOpenNew(false)}
        open={openNew}
      />

      <UploadSigendOffer
        open={openUploadSignedOffer}
        onClose={() => setOpenUploadSignedOffer(false)}
      />
    </>
  );
};

export default Offers;
