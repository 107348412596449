import { Box, Button, Dialog, Link } from "@mui/material";
import { API_DELETE, API_POST, DBRequest, useConfig } from "../../../api/api";
import useFetch from "../../../api/useFetch";
import ExoTable from "../../../components/exo/ExoTable";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../../components/exo/ExoTableToolbar";
import { updateOrCreate } from "../../../components/special/updateOrCreate";
import { useTranslation } from "react-i18next";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import DateLabel from "../../../components/label/DateLabel";
import { ExoTableUser } from "../../../components/exo/ExoTableUser";
import { GenerateTimeSheets } from "./GenerateTimeSheets";
import { useRef, useState } from "react";
import { ExpandTimeSheet } from "./ExpandTimeSheet";
import { renderHours } from "../../time-tracking/worktime";
import { useNavigate } from "react-router-dom";
const TimesheetsTab = () => {
  const config = useConfig();
  const { t } = useTranslation();
  const tableRef = useRef(null);

  const navigate = useNavigate();

  const { apiData, setApiData, isLoading } = useFetch(
    "time-sheets?sortBy=startDate"
  );

  const [open, setOpen] = useState(false);

  function handleNewElement(newElement) {
    setOpen(false);
    if (tableRef.current) tableRef.current.addRows(newElement);
  }

  const Columns = [
    {
      header: t("Employee"), // header of column
      key: "user", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <ExoTableUser user={row.user} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) => navigate(`/tax-consultant/timesheets/${row.id}`),
    },
    {
      header: t("Worktime"), // header of column
      key: "minutesTracked", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace">{renderHours(row.minutesTracked)} h</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Monat"), // header of column
      key: "startDate", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "230px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Box className="monospace">
          {<DateLabel format="MM/YYYY" date={row.endDate} />}
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  return (
    <Box className="w-full flex flex-col gap-0 h-full">
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("Generate")}
          icon={<AutoFixNormalIcon />}
          onClick={() => setOpen(true)}
        />
      </ExoTableToolbar>
      <ExoTable
        ref={tableRef}
        extendElement={({ row, onDelete, onCreate }) => (
          <ExpandTimeSheet row={row} onDelete={onDelete} onCreate={onCreate} />
        )}
        data={apiData}
        isLoading={isLoading}
        columns={Columns}
        noBackground
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <GenerateTimeSheets
          onResponse={handleNewElement}
          onClose={() => setOpen(false)}
        />
      </Dialog>
    </Box>
  );
};

export default TimesheetsTab;
