import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useEffect, useState } from "react";
import Header from "../../components/special/Header";
import ExoTable from "../../components/exo/ExoTable";
import { MailStatus } from "../../components/special/Status";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";
import DateTimeLabel from "../../components/label/DateTimeLabel";
import { ExtendMail } from "./ExtendMail";
import { useNavigate } from "react-router-dom";

const ServerMails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const navigate = useNavigate();

  // table
  const Columns = [
    {
      header: t("Date"),
      key: "updatedAt",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "130px",
      sortable: true, // enable sort function for column
      cell: ({ row }) => <DateTimeLabel dateTime={row.updatedAt} />,
      hiddenMobile: true,
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <MailStatus number={row.status} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Subject"),
      key: "subject",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      onClick: (row) => navigate(`/servermails/${row.id}`),
    },
    {
      header: t("Receiver"),
      key: "receiver",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="flex flex-col gap-2">
          {row.receiver} <CC ccs={row.cc} />
        </Box>
      ),
    },
    {
      header: t("Attachments"),
      key: "attachmentCount",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <Box className="monospace">{row.attachmentCount}</Box>,
      hiddenMobile: true,
    },
  ];
  const [mails, setMails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "maillogs",
      method: API_GET,
      onResponse: setMails,
      onLoading: setIsLoading,
    });
  }, [config]);

  return (
    <>
      <Header title={t("Sent Mails")} />
      <ExoTable
        columns={Columns}
        data={mails}
        isLoading={isLoading}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendMail row={row} onUpdate={onUpdate} onDelete={onDelete} />
        )}
      />
    </>
  );
};
const CC = ({ ccs }) => {
  return (
    <Box>
      {ccs.map((cc) => (
        <Typography variant="h6">{cc.name}</Typography>
      ))}
    </Box>
  );
};
export default ServerMails;
