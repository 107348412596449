import CardContainer from "./layout/CardContainer";
import { useConfig } from "../../api/api";
import NumberIcon from "../dashboard-modules/display/NumberIcon";
import { Typography } from "@mui/material";

const MoneyCard = ({
  Icon,
  amount = 0,
  title = "Title",
  subTitle,
  span = 1,
}) => {
  const config = useConfig();
  return (
    <CardContainer span={span}>
      <NumberIcon
        Icon={Icon}
        number={`${amount} ${config && config.currency.currencySymbol}`}
        label={title}
      />
      {subTitle}
    </CardContainer>
  );
};

export default MoneyCard;
