import { useTheme, Box, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import ExoTable from "../../components/exo/ExoTable";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import UpdateIcon from "@mui/icons-material/Update";
import SickIcon from "@mui/icons-material/Sick";
import { RequestStatus } from "../../components/special/Status";
import NewTimeCompensation from "./NewTimeCompensation";
import NewVacation from "./NewVacation";
import NewSick from "./NewSick";
import {
  requestTypesIcons,
  requestTypesStrings,
} from "../../components/special/requestTypes";
import Feedback from "../../components/special/Feedback";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import DateTimeLabel from "../../components/label/DateTimeLabel";
import ExtendRequest from "./ExtendRequest";
import { hasPermissions } from "../../auth/hasPermissions";
import NewTimeCorrection from "../../components/new/NewTimeCorrection";
import ExoDialog from "../../components/exo/ExoDialog";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

const NewRequest = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const navigate = useNavigate();

  // tabel
  const [requests, setRequests] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [minutesPerDay, setMinutesPerDay] = useState(0);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: "employeerequests/own?sortOrder=desc&sortBy=id",
      method: API_GET,
      onResponse: setRequests,
      onLoading: setIsLoading,
    });
    DBRequest({
      config,
      path: "user/timetracking",
      method: API_GET,
      onResponse: handleResponseTimetracking,
    });
  }, [config]);

  function handleResponseTimetracking(response, state) {
    if (!state) return;
    setMinutesPerDay(response.data.minutesPerDay);
  }

  const columns = [
    {
      header: t("Identifier"), // header of column
      key: "id", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "90px", // width of column
      cell: ({ row }) => <Box className="monospace">{row.id}</Box>, // custom xml for cells in the column. you get the row as object
      hiddenMobile: true, // hides the column on mobile
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <RequestStatus number={row.status} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Type"),
      key: "type",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignmentw
      className: "w-full",
      cell: ({ row }) => (
        <Box className="flex flex-row gap-2">
          {requestTypesIcons[row.type]}
          {t(requestTypesStrings[row.type])}
        </Box>
      ),
      onClick: (row) => navigate(`/new-requests/${row.id}`),
    },

    {
      header: t("Requested"),
      key: "createdAt",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "150px",
      hiddenMobile: true,
      cell: ({ row }) => <DateTimeLabel dateTime={row.createdAt} />,
    },
    {
      header: t("Updated"),
      key: "updatedAt",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "150px",
      hiddenMobile: true,
      cell: ({ row }) => <DateTimeLabel dateTime={row.updatedAt} />,
    },
  ];

  // actions
  const [openNewTimeCorrection, setOpenNewTimeCorrection] = useState(false);
  const [openNewTimeCompensation, setOpenNewTimeCompensation] = useState(false);
  const [openNewVacation, setOpenNewVacation] = useState(false);
  const [openNewSick, setOpenNewSick] = useState(false);

  // update

  function updateRequests(newRequest) {
    setRequests({ ...requests, data: [newRequest, ...requests.data] });
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <>
      <Header title={t("New Request")} />
      <ExoTableToolbar>
        {hasPermissions("time-correction", "write") && (
          <ExoTableToolbarItem
            onClick={() => setOpenNewTimeCorrection(true)}
            text={t("Time Correction")}
            icon={<MoreTimeIcon />}
          />
        )}
        {false && hasPermissions("time-compensation", "write") && (
          <ExoTableToolbarItem
            onClick={() => setOpenNewTimeCompensation(true)}
            text={t("Time Compensation")}
            icon={<UpdateIcon />}
          />
        )}
        {false && hasPermissions("vacation", "write") && (
          <ExoTableToolbarItem
            onClick={() => setOpenNewVacation(true)}
            text={t("Vacation")}
            icon={<FlightTakeoffIcon />}
          />
        )}
        {false && hasPermissions("sick", "write") && (
          <ExoTableToolbarItem
            onClick={() => setOpenNewSick(true)}
            text={t("Sick")}
            icon={<SickIcon />}
          />
        )}
      </ExoTableToolbar>
      <ExoTable
        columns={columns}
        data={requests}
        isLoading={isLoading}
        extendElement={({ row, onUpdate }) => (
          <ExtendRequest request={row} onUpdate={onUpdate} />
        )}
      />

      <Feedback setState={setAlertState} state={alertState} />
      <ExoDialog open={openNewTimeCorrection}>
        <NewTimeCorrection
          onFeedback={handleFeedback}
          onCancle={() => setOpenNewTimeCorrection(false)}
          onCreate={updateRequests}
        />
      </ExoDialog>
      <Dialog open={openNewTimeCompensation}>
        <NewTimeCompensation
          minutesPerDay={minutesPerDay}
          onCancle={() => setOpenNewTimeCompensation(false)}
          onCreate={updateRequests}
        />
      </Dialog>
      <Dialog open={openNewVacation}>
        <NewVacation
          onCancle={() => setOpenNewVacation(false)}
          onCreate={updateRequests}
        />
      </Dialog>
      <Dialog open={openNewSick}>
        <NewSick
          onCancle={() => setOpenNewSick(false)}
          onCreate={updateRequests}
        />
      </Dialog>
    </>
  );
};

export default NewRequest;
