import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import ExoDialog from "../../ExoDialog";
import CardContainer from "../../../cards/layout/CardContainer";
import ProjectCard from "../../../cards/ProjectCard";
import ExoMap from "../../ExoMap";
import NoDataAnimation from "../../../animation/NoDataAnimation";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import RenderHTML from "../../../preview/RenderHTML";
import PictureNameCard from "../../../cards/PictureNameCards/PictureNameCard";

const TimeDetailsViewer = ({ open, onClose, time }) => {
  const { t } = useTranslation();

  if (!time) return;
  const mTimeStart = moment(time.timeStart);
  const mTimeEnd = moment(time.timeEnd);
  const diffInMinutes = time ? mTimeEnd.diff(mTimeStart, "minutes") : 0;

  var locations = [];

  const clockInPosition = {
    position: time.clockInMetadata ? time.clockInMetadata.loc.split(",") : null,
    type: "clockIn",
  };
  if (clockInPosition.position) locations.push(clockInPosition);

  const clockOutPosition = {
    position: time.clockInMetadata ? time.clockInMetadata.loc.split(",") : null,
    type: "clockOut",
  };
  if (clockOutPosition.position) locations.push(clockOutPosition);

  return (
    <ExoDialog open={open} onClose={onClose} className="flex flex-col gap-2">
      <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
        <CardContainer>
          <Box>
            <Typography variant="h6" textAlign="center">
              {t("Worktime")}
            </Typography>
            <Typography variant="h2" textAlign="center">{`${renderHours(
              diffInMinutes
            )} h`}</Typography>
            <Typography variant="h6" textAlign="center">
              {`${mTimeStart.format("hh:mm")} - ${mTimeEnd.format("hh:mm")}`}
            </Typography>
          </Box>
        </CardContainer>
        <ProjectCard project={time.project} />
        <PictureNameCard
          name={`${time.employee.salutation} ${time.employee.firstName} ${time.employee.lastName}`}
          pictureUrl={
            time.employee.picture && time.employee.picture.temporaryUrl
          }
          mail={time.employee.email}
          phone={time.employee.phone}
        />
      </Box>
      <Box className="h-full w-full grid grid-cols-1 md:grid-cols-3 gap-2">
        <Box className="flex justify-center items-center overflow-hidden relative rounded-lg md:col-span-2">
          {locations.length ? (
            <ExoMap
              positions={locations}
              startCenter={clockInPosition.position}
            />
          ) : (
            <>
              <ExoMap zoom={5} startCenter={[51, 10]} className=" blur-md" />
              <NoDataAnimation />
            </>
          )}
        </Box>

        <CardContainer>
          {time.comment ? (
            <Box className="w-full h-full">
              <RenderHTML HTML={time.comment} />
            </Box>
          ) : (
            <Typography>{t("No Comment")}</Typography>
          )}
        </CardContainer>
      </Box>
    </ExoDialog>
  );
};

export default TimeDetailsViewer;
