import { Box, useTheme, Avatar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { tokens } from "../../global/theme/tokens";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ExoTable from "../../components/exo/ExoTable";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { InvoiceStatus } from "../../components/special/Status";
import ExtendInvoice from "./extendInvoice";
import Header from "../../components/special/Header";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import NewInvoice from "../../components/new/NewInvoice";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../components/label/MoneyLabel";
import useFetch from "../../api/useFetch";
import { useNavigate } from "react-router-dom";
import { recipientTypeMap } from "../../components/routing/routingMaps";

const Invoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { apiData, isLoading, setApiData } = useFetch("invoices");

  // table
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <InvoiceStatus number={row.status}></InvoiceStatus>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Title"),
      key: "title",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      onClick: (row) => navigate(`/invoices/${row.id}`),
    },
    {
      header: t("Client"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "270px",
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) =>
        navigate(`/${recipientTypeMap[row.recipientType]}/${row.recipient.id}`),
    },
    {
      header: t("Sum"),
      key: "amount",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      className: "w-full lg:w-auto",
      cell: ({ row }) => <MoneyLabel money={row.amount} />,
      sortable: true, // enable sort function for column
    },
  ];
  const config = useConfig();

  // new invoice
  const [openNew, setOpenNew] = useState(false);
  const handleClickNewInvoice = (event, reason) => {
    setOpenNew(!openNew);
  };

  return (
    <>
      <Header title={t("Invoices")} />
      <ExoTableToolbar>
        <ExoTableToolbarItem
          onClick={handleClickNewInvoice}
          text={t("New Invoice")}
          icon={<NoteAddIcon />}
        />
      </ExoTableToolbar>

      <ExoTable
        data={apiData}
        isLoading={!Boolean(apiData)}
        columns={Columns}
        extendElement={({ row, onUpdate }) => (
          <ExtendInvoice row={row} onUpdate={onUpdate} />
        )}
      />
      <NewInvoice
        open={openNew}
        onClose={() => setOpenNew(false)}
        invoices={apiData}
        setInvoices={setApiData}
      />
    </>
  );
};

export default Invoices;
