import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { ExpandPayroll } from "./ExpandPayroll";
import useFetch from "../../../api/useFetch";
export const ShowPayroll = () => {
  const { id } = useParams();

  const { apiData } = useFetch(`payrolls/${id}`);

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      {apiData && <ExpandPayroll row={apiData?.data} />}
    </Box>
  );
};
