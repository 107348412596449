import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { ExpandTimeSheet } from "./ExpandTimeSheet";
import useFetch from "../../../api/useFetch";
export const ShowTimeSheet = () => {
  const { id } = useParams();

  const { apiData } = useFetch(`time-sheets/${id}`);

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      {apiData && <ExpandTimeSheet row={apiData?.data} />}
    </Box>
  );
};
