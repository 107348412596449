import {
  Accordion,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import useFetch from '../../api/useFetch';
import { useTranslation } from 'react-i18next';
import UserStack from '../../components/exo/UserStack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useState } from 'react';
import { tokens } from '../../global/theme/tokens';
import { getInitials } from '../../components/exo/ExoAvatar';
import { t } from 'i18next';
import DateLabel from '../../components/label/DateLabel';
import { Status } from '../../components/special/Status';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { API_DELETE, DBRequest, useConfig } from '../../api/api';
import MoneyLabel from '../../components/label/MoneyLabel';
import { updateOrCreate } from '../../components/special/updateOrCreate';

export const LicensingManagement = () => {
  const { apiData, setApiData } = useFetch('licensing/bookings');

  function handleChange(feature) {
    console.log(feature);

    const updatedApiData = updateOrCreate(apiData, feature);
    setApiData(updatedApiData);
  }

  return (
    <Box className="w-full flex flex-col">
      {apiData?.data.map((feature) => (
        <Feature key={feature.id} feature={feature} onChange={handleChange} />
      ))}
    </Box>
  );
};

const Feature = ({ feature, onChange }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [toggelValue, setToggelValue] = useState('activeBookings');

  return (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary>
        <Box className="flex justify-between w-full items-center">
          <Typography variant="h5" fontWeight={500}>
            {t(feature.label)}
          </Typography>
          <UserStack users={feature.users} />
        </Box>
      </AccordionSummary>
      <Box className=" px-4 pb-4">
        <ToggleButtonGroup
          size="small"
          value={toggelValue}
          exclusive
          onChange={(e) => setToggelValue(e.target.value)}
          aria-label="Project Status"
          className="backdrop-blur-lg"
        >
          <ToggleButton value="activeBookings">{t('Active')}</ToggleButton>
          <ToggleButton value="historicBookings">{t('Outdated')}</ToggleButton>
        </ToggleButtonGroup>
        <Box className="flex flex-col pt-2 gap-1">
          {feature[toggelValue]?.map((booking) => (
            <Booking booking={booking} feature={feature} onChange={onChange} />
          ))}
        </Box>
      </Box>
    </Accordion>
  );
};

const Booking = ({ booking, feature, onChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();

  const user = booking.user;
  const name = `${user?.firstName} ${user?.lastName}`;

  const bookedBy = booking.bookedBy;
  const bookedByName = `${bookedBy?.firstName} ${bookedBy?.lastName}`;

  const isActive = Boolean(booking.autorenewAt);

  function terminateBooking() {
    DBRequest({
      config,
      path: `licensing/bookings/${booking.id}`,
      method: API_DELETE,
      onResponse: terminateBookingInFeature,
    });
  }

  function terminateBookingInFeature() {
    console.log(feature);
    var newBookings = [];

    feature.activeBookings.forEach((element) => {
      if (element.id === booking.id)
        return newBookings.push({ ...booking, autorenewAt: null });
      newBookings.push(element);
    });

    onChange({ ...feature, activeBookings: newBookings });
  }

  const intervalMap = {
    monthly: 1,
    annually: 12,
    biannualy: 24,
  };

  return (
    <Box
      className="flex flex-col rounded-lg items-center"
      sx={{
        border: `1px solid ${isActive ? colors.success : colors.error}30`,
        bgcolor: isActive ? colors.success + '20' : colors.error + '20',
      }}
    >
      <Box className="w-full flex justify-between gap-2 px-4 pt-2">
        <Box className="flex gap-2">
          {isActive ? <DirectionsRunIcon /> : <HourglassBottomIcon />}
          <Typography>{isActive ? t('Running') : t('Terminated')}</Typography>

          <Typography variant="h6" className="flex gap-2 pl-8">
            <MoneyLabel
              money={booking.totalPrice / intervalMap[booking.paymentInterval]}
            />
            {t('per month')}
          </Typography>
        </Box>

        {isActive && (
          <Button variant="outlined" color="error" onClick={terminateBooking}>
            {t('Terminate')}
          </Button>
        )}
      </Box>

      <Box className="flex w-full gap-4 md:gap-8 flex-col md:flex-row md:items-center pb-2 px-4">
        <Box className="w-full">
          <Typography variant="h6" fontSize={11}>
            {t('Booked by')}
          </Typography>
          {bookedBy ? (
            <Box className="flex gap-2 items-center">
              <Avatar
                sx={{
                  color: colors.grey[700],
                  width: '30px',
                  height: '30px',
                  fontSize: '12px',
                }}
                alt={bookedByName}
                src={bookedBy?.picture?.thumbnailUrl}
              >
                {getInitials(bookedByName)}
              </Avatar>
              <Typography>{bookedByName}</Typography>
            </Box>
          ) : (
            <Box>
              <Typography>{t('System')}</Typography>
            </Box>
          )}
        </Box>

        <ArrowForwardIcon className=" opacity-50 rotate-90 md:rotate-0" />

        <Box className="w-full pb-4">
          <Typography variant="h6" fontSize={11}>
            {t('Used by')}
          </Typography>
          <Box className="flex gap-2 items-center">
            <Avatar
              sx={{
                color: colors.grey[700],
                width: '30px',
                height: '30px',
                fontSize: '12px',
              }}
              alt={name}
              src={user?.picture?.thumbnailUrl}
            >
              {getInitials(name)}
            </Avatar>
            <Typography>{name}</Typography>
          </Box>
        </Box>
      </Box>

      <Divider className=" w-[90%]" />

      <Box className="w-full flex flex-col gap-2 pt-4 md:flex-row justify-between px-4 pb-2">
        <Box>
          <Typography variant="h6" fontSize={11}>
            {t('purchased on')}
          </Typography>
          <Typography>
            <DateLabel date={booking.purchaseDate} />
          </Typography>
        </Box>

        <Box>
          <Typography variant="h6" fontSize={11}>
            {t('ends at')}
          </Typography>
          <Typography>
            <DateLabel date={booking.endDate} />
          </Typography>
        </Box>

        {isActive && (
          <>
            <Box>
              <Typography variant="h6" fontSize={11}>
                {t('automatic renewal at')}
              </Typography>
              <Typography>
                <DateLabel date={booking.autorenewAt} />
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" fontSize={11}>
                {t('payment interval')}
              </Typography>
              <Typography>{t(booking.paymentInterval)}</Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
