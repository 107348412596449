import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ExtendRequest from "./ExtendRequest";
import useFetch from "../../api/useFetch";
export const ShowRequest = () => {
  const { id } = useParams();

  const { apiData } = useFetch(`employeerequests/${id}`);

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      {apiData && <ExtendRequest request={apiData?.data} />}
    </Box>
  );
};
