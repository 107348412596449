import { Box, useTheme, Typography } from '@mui/material';
import { TimeElement } from './timeline/TimeElement';
import { useEffect, useState } from 'react';
import { tokens } from '../../../global/theme/tokens';
import { renderHours } from '../../time-tracking/worktime';
import moment from 'moment';

export const WorkTimeline = ({
  times,
  format,
  hoursPerDay,
  height = 115,
  activeTime,
  prefix,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const widthInPercent = 100 / times.length;

  const [maxValue, setMaxValue] = useState(null);
  const [limitLinePosition, setLimitLinePosition] = useState(null);

  useEffect(() => {
    calculateMaxValue();
  }, [times]);

  function calculateMaxValue() {
    const secondsPerDay = hoursPerDay * 3600;
    var newMaxValue = secondsPerDay;
    times.forEach((time) => {
      const seconds = parseInt(time.totalTimeSeconds);
      if (seconds > newMaxValue) newMaxValue = seconds;
    });

    calculateLimitLine(newMaxValue);
    setMaxValue(newMaxValue);
  }

  function calculateLimitLine(maxSeconds) {
    const secondsPerDay = hoursPerDay * 3600;

    if (secondsPerDay < maxSeconds) {
      // show line becouse more than 100% work
      const percent = secondsPerDay / maxSeconds;
      const position = height - percent * height + 14 - 4; // 14px for the duration label on top + 4px padding top

      if (position < height) setLimitLinePosition(position);
    } else {
      setLimitLinePosition(null);
    }
  }

  return (
    <Box className="@container w-full">
      <Box
        className={`grid w-full relative gap-1 pt-1 px-1 @lg:pl-[45px] overflow-x-auto @lg:overflow-hidden`}
        sx={{
          gridTemplateColumns: `repeat(${times.length}, minmax(0, 1fr))`,
        }}
      >
        {limitLinePosition && (
          <Box
            sx={{
              borderBottom: `1px dashed ${colors.selected}`,
              top: `${limitLinePosition}px`,
            }}
            className={`w-full absolute z-10 justify-start items-center h-0 ml-0 @lg:ml-[45px] flex`}
          >
            <Typography
              className="-translate-x-full pr-2 hidden @lg:block monospace"
              fontSize={10}
            >
              {renderHours(hoursPerDay * 60)}h
            </Typography>
          </Box>
        )}
        {times.map((time) => (
          <TimeElement
            key={time.date}
            time={time}
            widthInPercent={widthInPercent}
            format={format}
            maxValue={maxValue}
            height={height}
            prefix={prefix}
            activeTime={
              moment(time.date).format('DD-MM-YYYY') ===
                moment().format('DD-MM-YYYY') && activeTime
            }
            onMaxValueChange={setMaxValue}
          />
        ))}
      </Box>
    </Box>
  );
};
