import {
  Avatar,
  AvatarGroup,
  Box,
  Popover,
  Tooltip,
  useTheme,
  Typography,
} from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import { getInitials } from './ExoAvatar';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const UserStack = ({ users, displayCount = 5, className }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'users-popover' : undefined;

  return (
    <>
      <AvatarGroup
        onClick={handleClick}
        className={className}
        total={users.length}
        sx={{
          cursor: 'pointer',
          '& .MuiAvatar-root': {
            borderColor: colors.selected,
            borderWidth: '1px',
            backgroundColor: colors.glassSolid,
          },
        }}
      >
        {users.map(
          (user, index) =>
            index < displayCount && (
              <Tooltip
                title={`${user.firstName} ${user.lastName}`}
                key={user.id}
              >
                <Avatar
                  sx={{
                    color: colors.grey[700],
                    width: '30px',
                    height: '30px',
                    fontSize: '12px',
                  }}
                  alt={`${user.firstName} ${user.lastName}`}
                  src={user.picture && user.picture.thumbnailUrl}
                >
                  {getInitials(`${user.firstName} ${user.lastName}`)}
                </Avatar>
              </Tooltip>
            )
        )}
      </AvatarGroup>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '.MuiBackdrop-root': {
            backdropFilter: 'unset',
            bgcolor: 'unset',
          },
        }}
      >
        <Box className="flex flex-col gap-2 p-4">
          <Typography variant="h6">{`${users.length} ${t(
            'Employee'
          )}`}</Typography>
          {users.map((user) => (
            <Box className="flex gap-2 items-center" key={user.id}>
              <Avatar
                sx={{
                  color: colors.grey[700],
                }}
                alt={`${user.firstName} ${user.lastName}`}
                src={user.picture && user.picture.thumbnailUrl}
                size="40px"
              >
                {getInitials(`${user.firstName} ${user.lastName}`)}
              </Avatar>

              <Typography>{`${user.salutation} ${user.firstName} ${user.lastName}`}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default UserStack;
