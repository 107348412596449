import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";
import { useConfig } from "../../../api/api";
import "moment/locale/en-gb";
import { TextField } from "@mui/material";

const moment = require("moment");

const TypeDate = ({
  field,
  updateValidationOnChange,
  dataCollection,
  errors,
}) => {
  const [date, setDate] = useState(null);
  const [format, setFormat] = useState(field.format || "YYYY-MM-DD");

  const config = useConfig();

  useEffect(() => {
    if (!config || field.format) return;
    setFormat(config.general.dateOutputFormat);
  }, [config]);

  useEffect(() => {
    if (dataCollection[field.key] && !date) {
      setDate(moment(dataCollection[field.key], field.format || "YYYY-MM-DD"));
    }
  }, [format, dataCollection[field.key]]);

  useEffect(() => {
    if (!dataCollection[field.key]) setDate(null);
  }, [dataCollection[field.key]]);

  // Set the locale to one that starts the week on Monday
  useEffect(() => {
    moment.locale("en-gb"); // "en-gb" uses Monday as the first day of the week
  }, []);

  // handle input changes
  function handleDateChange(newDate) {
    updateValidationOnChange(
      field.key,
      newDate ? newDate.format(field.format || "YYYY-MM-DD") : null
    );
    setDate(newDate);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        views={field.views || ["day"]}
        className="w-full"
        onChange={handleDateChange}
        value={date}
        format={format}
        label={field.label}
        sx={field?.sx}
      />
    </LocalizationProvider>
  );
};

export default TypeDate;
