import { Box, IconButton, Link, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import Header from "../../components/special/Header";
import { useState } from "react";
import ExoTable from "../../components/exo/ExoTable";
import ExoAvatar from "../../components/exo/ExoAvatar";
import LanguageIcon from "@mui/icons-material/Language";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import NewContact from "../../components/new/NewContact";
import ExtendContact from "./ExtendContact";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../components/label/MoneyLabel";
import useFetch from "../../api/useFetch";
import ExoDialog from "../../components/exo/ExoDialog";
import { useNavigate } from "react-router-dom";

const Contacts = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { apiData, setApiData } = useFetch(`contacts`);

  const columns = [
    {
      header: t("Contact Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "130px", // width of column
      hiddenMobile: true, // hides the column on mobile
      cell: ({ row }) => <Box className="monospace">{row.nr}</Box>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Name"), // header of column
      key: "nameShort", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="flex flex-row justify-start items-center gap-2">
          <ExoAvatar
            name={row.firstName + " " + row.lastName}
            size="35px"
            type="person"
            color={row.color}
          />
          {row.salutation + " " + row.firstName + " " + row.lastName}
        </Box>
      ),
      onClick: (row) => navigate(`/contacts/${row.id}`),
    },

    {
      header: t("Position"), // header of column
      key: "position", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
    },

    {
      header: t("Website"), // header of column
      key: "url", // key of column in row-object
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      sortable: true, // enable sort function for column
      width: "80px", // width of column
      hiddenMobile: true,
      cell: ({ row }) =>
        row.url && (
          <Link href={row.url} target="_blank">
            <IconButton>
              <LanguageIcon />
            </IconButton>
          </Link>
        ),
    },
    {
      header: t("Sales"), // header of column
      key: "salesTotal", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "120px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => <MoneyLabel money={row.salesTotal} />,
    },
  ];

  // new contact
  const [open, setOpen] = useState(false);

  function handleNewContact(response) {
    var newContacts = apiData;
    newContacts.data.unshift(response.data);
    setApiData(newContacts);
  }

  return (
    <>
      <Header
        title={t("Contacts")}
        subtitle="Here you can find all your contacts and those shared with you."
      />
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Contact")}
          icon={<PersonAddIcon />}
          onClick={() => setOpen(true)}
        />
      </ExoTableToolbar>
      <ExoTable
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendContact row={row} onUpdate={onUpdate} onDelete={onDelete} />
        )}
        columns={columns}
        data={apiData}
        isLoading={Boolean(!apiData)}
      />

      <ExoDialog open={open} limitWidth>
        <NewContact
          fullWidth
          onClose={() => setOpen(false)}
          onCreate={handleNewContact}
        />
      </ExoDialog>
    </>
  );
};

export default Contacts;
