import { useTheme } from '@emotion/react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ExoTabs from '../../components/exo/ExoTabs';
import EmployeeContact from './info-tabs/EmployeeContact';
import EmployeeAddress from './info-tabs/EmployeeAddress';
import EmployeePersonalData from './info-tabs/EmployeePersonalData';
import EmployeeContract from './info-tabs/EmployeeContract';
import ExoAvatar from '../../components/exo/ExoAvatar';
import { useTranslation } from 'react-i18next';
import NewUser from '../../components/new/NewUser';
import { useState } from 'react';
import LockResetIcon from '@mui/icons-material/LockReset';
import { API_POST, DBRequest, useConfig } from '../../api/api';
import Loader from '../../components/special/Loader';
import NewWorkContract from '../../components/new/NewWorkContract';
import MenuIcon from '@mui/icons-material/Menu';
import EmployeePayrolls from './info-tabs/EmployeePayrolls';
import { EmployeeTimes } from './info-tabs/EmployeeTimes';
import { TimeAccountCard } from '../time-tracking/TimeAccountCard';
import { hasPermissions } from '../../auth/hasPermissions';
import ExoDialog from '../../components/exo/ExoDialog';
import { EmployeeLinzenses } from './info-tabs/EmployeeLinzenses';

const EmployeeInfo = ({
  employeeInfo,
  onChange,
  onOpen,
  isLoading,
  noHead,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const [openEdit, setOpenEdit] = useState(false);

  const tabs = [
    {
      label: t('Worktime'),
      content: <EmployeeTimes employeeInfo={employeeInfo} noPicture />,
    },
    {
      label: t('Contact Data'),
      content: <EmployeeContact employeeInfo={employeeInfo} />,
    },
    {
      label: t('Address'),
      content: <EmployeeAddress employeeInfo={employeeInfo} />,
    },
    {
      label: t('Private'),
      content: <EmployeePersonalData employeeInfo={employeeInfo} />,
    },
    {
      label: t('Payrolls'),
      content: <EmployeePayrolls employeeInfo={employeeInfo} />,
    },
    {
      label: t('Contract'),
      content: (
        <EmployeeContract
          employeeInfo={employeeInfo}
          onOpen={() => setOpenNewWorkContract(true)}
          onChange={onChange}
        />
      ),
    },
    {
      label: t('Licenses'),
      content: <EmployeeLinzenses employeeInfo={employeeInfo} />,
    },
  ];

  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  function handleResetPasswort() {
    const data = { email: employeeInfo.email };
    DBRequest({
      config,
      path: config.API_PWFORGOT,
      customPath: true,
      method: API_POST(data),
      onLoading: setIsLoadingReset,
      onResponse: handleResponse,
    });
  }

  function handleResponse() {
    setOpenResetPassword(false);
  }

  const [openNewWorkContract, setOpenNewWorkContract] = useState(false);

  const TopSettings = () => {
    return (
      <Box className="flex items-start gap-2">
        <IconButton onClick={() => setOpenResetPassword(true)}>
          <LockResetIcon />
        </IconButton>
        {hasPermissions('user', 'write') && (
          <IconButton onClick={() => setOpenEdit(true)}>
            <ManageAccountsOutlinedIcon />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Box
      className="w-full h-full relative flex flex-col overflow-auto rounded-lg backdrop-blur-lg"
      sx={{
        backgroundColor: colors.glass,
      }}
    >
      {onOpen && (
        <Box className=" absolute top-2 left-2 flex lg:hidden z-10">
          <IconButton onClick={onOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
      )}
      {isLoading ? (
        <Box className="w-full flex justify-center items-center flex-col py-4 border-b-2 gap-2">
          <Skeleton width="150px" height="150px" variant="circular" />
          <Box className="flex justify-center items-center flex-col">
            <Skeleton width="300px" height="38px" />
            <Skeleton width="250px" height="24px" />
          </Box>
        </Box>
      ) : (
        <>
          {!noHead && <TopSection employeeInfo={employeeInfo} />}
          <ExoTabs tabs={tabs} noBackground TopBar={TopSettings} />
        </>
      )}
      <ExoDialog open={openEdit} limitWidth>
        <NewUser
          startData={{ ...employeeInfo }}
          onClose={() => setOpenEdit(false)}
          onCreate={onChange}
        />
      </ExoDialog>
      <Dialog open={openResetPassword}>
        <Loader active={isLoadingReset} />
        <Box className=" p-4 w-96 max-w-fit flex flex-col gap-2">
          <Typography variant="h3">{t('Reset Password')}</Typography>
          <Typography variant="body1">
            {t(
              "An e-mail is sent to the user's e-mail address, which the user can use to reassign their password."
            )}
          </Typography>
          <Box className="w-full flex justify-between">
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpenResetPassword(false)}
            >
              {t('Cancle')}
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleResetPasswort}
            >
              {t('Send')}
            </Button>
          </Box>
        </Box>
      </Dialog>
      <NewWorkContract
        startData={{
          user: { id: employeeInfo && employeeInfo.id },
        }}
        open={openNewWorkContract}
        onClose={() => setOpenNewWorkContract(false)}
      />
    </Box>
  );
};

const TopSection = ({ employeeInfo }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  return (
    <Box
      className="w-full  py-4 border-b-2 gap-2 shadow-lg @container"
      sx={{
        borderWidth: '0 0 1px 0',
        borderColor: colors.primary[400],
      }}
    >
      <Box className="flex justify-around items-center flex-col @lg:flex-row gap-2">
        <Box className="flex justify-center items-center flex-col">
          <ExoAvatar
            name={employeeInfo.lastName}
            picture={
              employeeInfo.picture ? employeeInfo.picture.temporaryUrl : ''
            }
            size="100px"
          />
          <Typography variant="h2" textAlign="center" className="pt-3">
            {`${employeeInfo.firstName} ${employeeInfo.lastName}`}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: colors.primary[600],
            }}
          >
            {employeeInfo.contract && employeeInfo.contract.position}
          </Typography>
        </Box>
        <TimeAccountCard
          minutes={employeeInfo.timeAccount}
          label={t('Time Account')}
          size="small"
          height="80px"
          width="150px"
          color="glass"
        />
      </Box>
    </Box>
  );
};

export default EmployeeInfo;
