import PictureNameCard from "../../../components/cards/PictureNameCards/PictureNameCard";

const ProjectClientCard = ({ project }) => {
  return (
    <>
      {project.clientType === "company" && (
        <PictureNameCard
          pictureUrl={
            project.client.logo ? project.client.logo.thumbnailUrl : ""
          }
          name={project.client.name}
          url={project.client.url}
          phone={project.client.phone}
          mail={project.client.mail}
          type={project.clientType}
        />
      )}
      {project.clientType === "contact" && (
        <PictureNameCard
          name={project.client.firstName + " " + project.client.lastName}
          url={project.client.url}
          phone={project.client.phone}
          mail={project.client.mail}
          type={project.clientType}
        />
      )}
    </>
  );
};

export default ProjectClientCard;
