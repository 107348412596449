import React from "react";
import {
  API_PATCH,
  API_POST,
  DBRequest,
  useConfig,
} from "../../../../../api/api";
import { useTranslation } from "react-i18next";
import ExoForm from "../../../ExoForm";
import * as Yup from "yup";

export const WPEFilesForm = ({
  workPackage,
  startDataCollection,
  onCreate,
  onClose,
  sx,
  className,
}) => {
  const config = useConfig();
  const { t } = useTranslation();

  const isUpdate = Boolean(startDataCollection?.id);

  function handleSubmit(data) {
    const reqData = {
      workPackage: workPackage,
      ...data,
    };

    DBRequest({
      config,
      path: isUpdate
        ? `work-packages/elements/file/${startDataCollection.id}`
        : `work-packages/elements/file`,
      method: isUpdate ? API_PATCH(reqData) : API_POST(reqData),
      onResponse: handleResponse,
    });
  }

  function handleResponse(res) {
    onClose();
    onCreate(res.data);
  }

  const fields = [
    {
      label: t("Name"),
      key: "name",
      type: "text",
    },
    {
      label: t("File Type"),
      key: "fileTypeId",
      type: "fileTypeId",
    },
    {
      label: t("Foldername"),
      key: "root",
      type: "text",
    },
    {
      label: `${t("Status")} (${t("optional")})`,
      key: "folderStatusMap",
      type: "folderStatus",
    },
  ];
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Is required")),
    root: Yup.string().required(t("Is required")),
    fileTypeId: Yup.number().required(t("Is required")),
  });

  return (
    <ExoForm
      onSubmit={handleSubmit}
      onCancle={onClose}
      fields={fields}
      sx={sx}
      className={className}
      validationSchema={validationSchema}
      startDataCollection={startDataCollection}
      header={
        isUpdate
          ? t("Update Work Package Element")
          : t("Add Work Package Element")
      }
    />
  );
};
