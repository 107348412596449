import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import useFetch from "../../api/useFetch";
import { FinApiWebForm } from "./fin-api/FinApiWebForm";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { useTranslation } from "react-i18next";

const ExoBankAccounts = ({
  onClose,
  onNewAccounts = () => {},
  webformApi = "bankaccounts/create-webform",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();
  const { apiData } = useFetch(webformApi);

  function triggerAccountLoad() {
    DBRequest({
      config,
      path: "bankaccounts/webform-status",
      method: API_GET,
      onResponse: handleResponse,
    });
  }

  function handleResponse(data) {
    onNewAccounts(data.data);
    onClose();
  }

  return (
    <Box className="w-full h-full flex justify-center items-center">
      {!apiData ? (
        <Box className="flex flex-col items-center">
          <span className="loader"></span>
          <Typography>{t("Creating Webform")}</Typography>
        </Box>
      ) : (
        <FinApiWebForm
          url={config.finApiWebformUrl}
          token={apiData.data.token}
          onComplete={triggerAccountLoad}
          onLoadError={onClose}
          onAbort={triggerAccountLoad}
          onFail={onClose}
        />
      )}
    </Box>
  );
};

export default ExoBankAccounts;
