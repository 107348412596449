import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { ExtendMail } from "./ExtendMail";
export const ShowMail = () => {
  const { id } = useParams();

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <ExtendMail row={{ id, onUpdate: () => {}, onDelete: () => {} }} />
    </Box>
  );
};
