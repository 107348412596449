import { tokens } from '../../global/theme/tokens';
import {
  useTheme,
  Box,
  Skeleton,
  Popper,
  ClickAwayListener,
  Button,
  Typography,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useTranslation } from 'react-i18next';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import { useEffect, useState } from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export const ContractStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Open')} variant="open" />;
    case 1:
      return <Status text={t('Accepted')} variant="accepted" />;
    case 2:
      return <Status text={t('Rejected')} variant="rejected" />;
  }
};
export const OfferStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Sent')} variant="sent" />;
    case 1:
      return <Status text={t('Accepted')} variant="accepted" />;
    case 2:
      return <Status text={t('Finished')} variant="finished" />;
    case 3:
      return <Status text={t('Rejected')} variant="rejected" />;
    case 4:
      return <Status text={t('Created')} variant="open" />;
  }
};

export const MailStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Queued')} variant="qued" />;
    case 1:
      return <Status text={t('Sent')} variant="accepted" />;
    case 2:
      return <Status text={t('Failed')} variant="rejected" />;
  }
};

export const LetterStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Created')} variant="accepted" />;
    case 1:
      return <Status text={t('Sent')} variant="sent" />;
    case 2:
      return <Status text={t('Declined')} variant="rejected" />;
  }
};

export const InvoiceStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Created')} variant="open" />;
    case 1:
      return <Status text={t('Payed')} variant="accepted" />;
    case 2:
      return <Status text={t('Sent')} variant="sent" />;
    case 3:
      return <Status text={t('In Arrears')} variant="expired" />;
    case 4:
      return <Status text={t('Refusal to Pay')} variant="rejected" />;
  }
};

export const RequestStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Pending')} variant="open" />;
    case 1:
      return <Status text={t('Accepted')} variant="accepted" />;
    case 2:
      return <Status text={t('Declined')} variant="rejected" />;
  }
};

export const OrderStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Created')} variant="open" />;
    case 1:
      return <Status text={t('Finished')} variant="finished" />;
    case 2:
      return <Status text={t('Cancled')} variant="rejected" />;
    case 3:
      return <Status text={t('Sent')} variant="sent" />;
  }
};

export const ProjectStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Running')} variant="open" />;
    case 1:
      return <Status text={t('Finished')} variant="finished" />;
    case 2:
      return <Status text={t('Cancled')} variant="rejected" />;
  }
};

export const PaymentReminderStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t('Ready')} variant="draft" />;
    case 1:
      return <Status text={t('Running')} variant="open" />;
    case 2:
      return <Status text={t('Paused')} variant="expired" />;
    case 3:
      return <Status text={t('Finished')} variant="finished" />;
  }
};

export const Status = ({ text, variant, onClick, statusIcon, statusColor }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  var icon = <EmailIcon />;
  var color = colors.primary[600];
  switch (variant) {
    case 'draft':
      icon = <ModeEditIcon />;
      color = colors.blueAccent[600];
      break;
    case 'open':
      icon = <HourglassFullIcon />;
      color = colors.orangeAccent[600];
      break;
    case 'sent':
      icon = <EmailIcon />;
      color = colors.blueAccent[600];
      break;
    case 'accepted':
      icon = <CheckCircleIcon />;
      color = colors.greenAccent[600];
      break;
    case 'rejected':
      icon = <CancelIcon />;
      color = colors.redAccent[600];
      break;
    case 'expired':
      icon = <QueryBuilderIcon />;
      color = colors.redAccent[600];
      break;
    case 'finished':
      icon = <FlagCircleIcon />;
      color = colors.grey[600];
      break;
    case 'legal':
      icon = <GavelOutlinedIcon />;
      color = colors.redAccent[500];
      break;
    case 'base':
      icon = <QuestionMarkIcon />;
      color = colors.grey[600];
      break;
  }

  return (
    <Box
      onClick={onClick}
      className="flex flex-row gap-2 rounded-lg md:rounded-full px-2 md:pl-2 md:pr-3 py-1 w-fit md:w-full"
      sx={{
        bgcolor: (statusColor || color) + '25',
        color: statusColor || color,
      }}
    >
      {statusIcon || icon}
      <Box className="hidden md:block">{text}</Box>
    </Box>
  );
};

const StatusIconVariantsMap = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return {
    draft: {
      Icon: ModeEditIcon,
      color: colors.blueAccent[600],
    },
    open: {
      Icon: HourglassFullIcon,
      color: colors.blueAccent[600],
    },
    sent: {
      Icon: EmailIcon,
      color: colors.blueAccent[600],
    },
    accepted: {
      Icon: CheckCircleIcon,
      color: colors.greenAccent[600],
    },
    rejected: {
      Icon: CancelIcon,
      color: colors.redAccent[600],
    },
    expired: {
      Icon: QueryBuilderIcon,
      color: colors.redAccent[600],
    },
    finished: {
      Icon: FlagCircleIcon,
      color: colors.grey[600],
    },
    legal: {
      Icon: GavelOutlinedIcon,
      color: colors.redAccent[500],
    },
    base: {
      Icon: QuestionMarkIcon,
      color: colors.grey[600],
    },
  };
};

export const FileStatus = ({ onChange, folderStatusMap, status }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [statusMap, setStatusMap] = useState(null);

  useEffect(() => {
    if (!folderStatusMap) return;

    var newStatusMap = {};
    folderStatusMap.forEach((element) => {
      newStatusMap[element.label] = element;
    });

    setStatusMap(newStatusMap);
  }, [folderStatusMap]);

  // change logic
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'setting' : undefined;

  if (!statusMap) return <Skeleton />;

  return (
    <Box>
      <Button
        sx={{ padding: 0, borderRadius: '9999px' }}
        onClick={onChange ? handleClick : null}
      >
        <Status
          text={
            status && statusMap[status]
              ? statusMap[status].label
              : t('No Status')
          }
          variant={
            status && statusMap[status] ? statusMap[status].variant : 'base'
          }
        />
      </Button>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="right-start"
        sx={{ paddingLeft: '5px', zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={anchorEl && handleClose}>
          <Box
            sx={{ bgcolor: colors.card }}
            className="backdrop-blur-lg rounded-lg flex flex-col gap-2 p-2 shadow-md "
          >
            <Typography variant="h6">{t('Change Status')}</Typography>
            <StatusMapElementButton
              key={t('No Status')}
              element={{ label: t('No Status'), variant: 'base' }}
              onSelect={() => onChange('')}
            />
            {folderStatusMap &&
              folderStatusMap.map((element) => (
                <StatusMapElementButton
                  key={element.label}
                  element={element}
                  onSelect={(statusMapElement) => {
                    handleClose();
                    onChange(statusMapElement.label);
                  }}
                />
              ))}
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

const StatusMapElementButton = ({ element, onSelect }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleClick() {
    onSelect(element);
  }

  const statusMap = StatusIconVariantsMap();

  const Icon = statusMap[element.variant].Icon;

  const color = statusMap[element.variant].color || colors.info;

  return (
    <Button
      onClick={handleClick}
      className="flex gap-2"
      variant={'text'}
      color={'secondary'}
      sx={{
        border: '1px solid transparent',
        ':hover': {
          bgcolor: color + '30',
          borderColor: color,
        },
      }}
    >
      {Icon && <Icon className="opacity-60" sx={{ color }} />}
      <Typography className="w-full" textAlign="left" sx={{ color }}>
        {element.label}
      </Typography>
    </Button>
  );
};
