import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../global/theme/tokens';
import FormFields from '../form/FormFields';
import MoneyLabel from '../label/MoneyLabel';
import PreviewPositions from '../preview/PreviewPositions';
import * as Yup from 'yup';

interface IItem {
  name: string;
  description?: React.ReactNode;
  taxRate: number;
  price: number;
  amount: number;
}

const ExoPaymentRequest = ({
  items,
  note = 'The billing cycle corresponds to the minimum contract term.',
  onSubmit,
  onCancle,
}: {
  items: IItem[];
  note: string;
  onSubmit: any;
  onCancle: any;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  const [dataCollection, setDataCollection] = React.useState({});

  function handleUpdateDataCollection(data) {
    validateDataCollection(data);
    setDataCollection({ ...dataCollection, ...data });
  }

  const fields = [
    {
      label: (
        <>
          {t('I have read the general terms and conditions and agree to them.')}
          <b>
            <Link
              sx={{ color: colors.info }}
              className="pl-4"
              href="https://exopaper.de/agbs"
              target="_blank"
            >
              {t('read now')} (https://exopaper.de/agb)
            </Link>
          </b>
        </>
      ),
      type: 'checkbox',
      key: 'agb',
    },
  ];

  const cols = [
    {
      label: t('Description'),
      key: 'name',
      align: 'left',
      hiddenMobile: false,
      type: 'custom',
      width: 'auto',
      cell: (row) => (
        <Box className="py-2">
          <Typography fontSize={16} fontWeight={600}>
            {row.name}
          </Typography>
          {row.description}
        </Box>
      ),
    },
    {
      label: t('Quantity'),
      key: 'amount',
      align: 'right',
      hiddenMobile: false,
      type: 'number',
      width: '70px',
      cell: (row) => <Box className="flex justify-end">{row.amount}</Box>,
    },
    {
      label: t('Price'),
      key: 'price',
      align: 'right',
      hiddenMobile: false,
      type: 'number',
      width: '100px',
      cell: (row) => (
        <Box className="flex justify-end">
          <MoneyLabel money={row.price} />
        </Box>
      ),
    },
    {
      label: t('Total Netto'),
      key: 'price',
      align: 'right',
      hiddenMobile: false,
      type: 'netto',
      width: '120px',
      cell: (row) => (
        <Box className="flex justify-end items-start">
          <MoneyLabel money={row.price * row.amount} />
        </Box>
      ),
    },
  ];

  // validation --------------------------
  const [valid, setValid] = React.useState(false);

  const validationSchema = Yup.object().shape({
    agb: Yup.string().oneOf(['1']).required('Date is required'),
  });

  const validateDataCollection = async (data) => {
    if (!validationSchema) {
      setValid(true);
      return;
    }
    try {
      await validationSchema.validate(data);

      // validation passed for the specific field
      setValid(true);
    } catch (err) {
      // validation failed for the specific field
      setValid(false);
    }
  };

  return (
    <Box>
      <Typography variant="h3" className="pb-8">
        {t('Payment preview')}
      </Typography>
      <PreviewPositions
        sx={{
          th: {
            backgroundColor: colors.selected,
          },
          'tr:nth-child(even):not(.sum-row) td': {
            backgroundColor: colors.card,
          },
          '.sum-row td': {
            backgroundColor: colors.card,
          },
        }}
        positions={items}
        columns={cols}
      />
      <Box
        className="mt-3 px-4 py-2 rounded-lg"
        sx={{ bgcolor: colors.info + '60' }}
      >
        <Typography>{t(note)}</Typography>
      </Box>

      <Box className="mt-5">
        <FormFields
          fields={fields}
          dataCollection={dataCollection}
          updateDataCollection={handleUpdateDataCollection}
          validationSchema={validationSchema}
        />
      </Box>

      <Box className="flex justify-between p-4 mt-4">
        <Button color="error" variant="outlined" onClick={onCancle}>
          {t('Cancle')}
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={onSubmit}
          disabled={!valid}
        >
          {t('Purchase')}
        </Button>
      </Box>
    </Box>
  );
};

export default ExoPaymentRequest;
