import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasPermissions } from '../../../auth/hasPermissions';
import NoDataAnimation from '../../../components/animation/NoDataAnimation';
import CardContainer from '../../../components/cards/layout/CardContainer';
import ExoHistory from '../../../components/exo/ExoHistory';
import { ExoTableToolbarItem } from '../../../components/exo/ExoTableToolbar';
import HourLabel from '../../../components/label/HourLabel';
import MoneyLabel from '../../../components/label/MoneyLabel';
import { ContractNode } from './contract-elements/ContractNode';

import UploadSigendContract from './contract-elements/UploadSignedContract';
import { updateOrCreate } from '../../../components/special/updateOrCreate';

const EmployeeContract = ({ employeeInfo, onOpen, onChange }) => {
  const { t } = useTranslation();
  const contracts = employeeInfo.contracts;

  const [activeContract, setActiveContract] = useState(null);
  const [contractToSign, setContractToSign] = useState(null);

  useEffect(() => {
    if (contracts) {
      contracts.forEach((element) => {
        if (element.isActive) setActiveContract(element);
      });
    }
  }, [contracts]);

  function handleContractChange(updatedContract) {
    const newContracts = updateOrCreate(contracts, updatedContract);
    onChange({ ...employeeInfo, contracts: newContracts });
  }

  if (!contracts) {
    return (
      <Box className="w-full h-full flex justify-center items-center relative">
        {hasPermissions('employee-contract', 'write') && (
          <ExoTableToolbarItem
            className=" absolute top-2 left-2"
            icon={<HistoryEduIcon />}
            text={t('New Contract')}
            onClick={onOpen}
          />
        )}
        <NoDataAnimation />
      </Box>
    );
  }

  const entries = contracts.map((contract) => {
    return {
      data: { ...contract, onSign: setContractToSign },
      date: contract.startDate,
      isActive: Boolean(contract.isActive),
      label: contract.position,
    };
  });

  return (
    <Box className="flex flex-col gap-3 items-start w-full relative @container ">
      {hasPermissions('employee-contract', 'write') && (
        <ExoTableToolbarItem
          icon={<HistoryEduIcon />}
          text={t('New workcontract')}
          onClick={onOpen}
        />
      )}
      {activeContract && (
        <>
          <Box className="grid grid-cols-1 @md:grid-cols-2 @2xl:grid-cols-3 w-full gap-4">
            <InfoCard
              label={t('Salery')}
              Icon={AttachMoneyIcon}
              value={activeContract.salary}
              type="money"
            />
            <InfoCard
              label={t('Hours per Week')}
              Icon={AccessTimeIcon}
              value={activeContract.hoursPerWeek}
              type="hours"
            />
            <InfoCard
              label={t('Vacation')}
              Icon={AccessTimeIcon}
              value={activeContract.vacation}
            />
          </Box>
        </>
      )}
      <ExoHistory entries={entries} EntryNode={ContractNode} />
      <UploadSigendContract
        open={Boolean(contractToSign)}
        onClose={() => setContractToSign(null)}
        contract={contractToSign}
        onUpload={handleContractChange}
      />
    </Box>
  );
};

const InfoCard = ({ Icon, label, value, type }) => {
  return (
    <CardContainer className="flex flex-col gap-2 justify-center items-center">
      <Icon />
      <Typography textAlign="center">{label}</Typography>
      <Typography className="monospace" variant="h3" textAlign="center">
        {type === 'money' && <MoneyLabel money={value || 0} />}
        {type === 'hours' && <HourLabel minutes={value * 60} />}
        {!type && value}
      </Typography>
    </CardContainer>
  );
};

export default EmployeeContract;
