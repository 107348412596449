import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ExtendReceipt from "./ExtendReceipt";
export const ShowReceipt = () => {
  const { id } = useParams();

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <ExtendReceipt row={{ id, onUpdate: () => {}, onDelete: () => {} }} />
    </Box>
  );
};
