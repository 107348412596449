import { Box, Button, Typography, useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import ExoAvatar from '../../components/exo/ExoAvatar';
import { renderHours } from './worktime';
import { useTranslation } from 'react-i18next';
import { useTimeTracking } from '../../components/time-tracking/AppTimeProvider';
import { AiProcessing } from '../../components/special/AiProcessing';

const ProjectTimeCard = ({
  project,
  onSelect,
  onDeselect,
  selected,
  onClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { secondsIn } = useTimeTracking();

  function handleClick() {
    if (onClick) onClick(project);
  }

  const clientName = project.client
    ? project.clientType === 'company'
      ? project.client.name
      : `${project.client.salutation} ${project.client.firstName} ${project.client.lastName}`
    : t('Internal');

  const projectImage =
    project.activeWorkPackage && project.activeWorkPackage.icon
      ? project.activeWorkPackage.icon?.temporaryUrl
      : project.image?.temporaryUrl;

  return (
    <Box
      className="w-[calc(50%-0.5rem)] sm:w-60 rounded-xl backdrop-blur-lg relative overflow-hidden"
      sx={{
        backgroundColor: colors.glass,
        width: selected && '240px',
      }}
      onClick={handleClick}
    >
      <Box
        className="w-full p-4 rounded-xl h-full"
        sx={{
          borderColor: colors.glass,
          borderWidth: '1px',
          ':hover': onClick && {
            borderColor: colors.success,
            backgroundColor: colors.success + '30',
            cursor: 'pointer',
          },
        }}
      >
        <Box className="flex justify-center items-center py-4">
          {project.image ? (
            <img src={projectImage} className="w-full h-32 object-contain" />
          ) : (
            <ExoAvatar
              picture={
                project.client &&
                project.client.logo &&
                project.client.logo.temporaryUrl
              }
              type={project.clientType || 'company'}
              name={
                project.client
                  ? project.clientType === 'company'
                    ? project.client.nameShort
                    : `${project.client.lastName} ${project.client.firstName}`
                  : ''
              }
              size="128px"
            />
          )}
        </Box>
        <Box>
          <Box className=" text-center h-20 flex justify-center items-center flex-col">
            {project.activeWorkPackage ? (
              <>
                <Typography variant="h4">
                  {project.activeWorkPackage.title}
                </Typography>
                <Typography variant="h6">{project.name}</Typography>
              </>
            ) : (
              <Typography variant="h5">{project.name}</Typography>
            )}
          </Box>
        </Box>
        <Typography
          className=" text-center text-4xl pb-2"
          variant="h2"
          fontWeight={500}
        >
          {project.activeWorkPackage
            ? renderHours(
                project.activeWorkPackage.totalTimeWorkedSeconds / 60 +
                  Math.round(secondsIn / 60)
              )
            : renderHours(project.timeTotal + Math.round(secondsIn / 60))}{' '}
          h
        </Typography>

        {selected && (
          <Box
            className="w-full flex flex-col gap-2 overflow-y-auto px-2 pt-4 pb-2"
            sx={{
              maxHeight: '150px',
            }}
          >
            <Button
              onClick={onDeselect}
              className=" w-full text-xl"
              variant="contained"
              color="error"
            >
              {t('Clock Out')}
            </Button>
          </Box>
        )}

        <Typography variant="h6" sx={{ fontSize: '11px' }}>
          {clientName}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProjectTimeCard;
