import React, { useState } from "react";
import ExoForm from "../../../ExoForm";
import {
  API_PATCH,
  API_POST,
  DBRequest,
  useConfig,
} from "../../../../../api/api";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const WPEToDoListForm = ({
  workPackage,
  startDataCollection,
  onCreate,
  onClose,
  sx,
  className,
}) => {
  const { t } = useTranslation();

  const config = useConfig();

  const [isLoading, setIsLoading] = useState(false);

  const isUpdate = Boolean(startDataCollection?.id);

  const fields = [
    {
      label: t("Name"),
      type: "text",
      key: "name",
    },
    {
      label: `${t("Description")} (${t("optional")})`,
      type: "richText",
      key: "description",
    },
  ];

  function handleSubmit(data) {
    const reqData = {
      ...data,
      tasks: [],
      workPackage,
    };

    DBRequest({
      config,
      path: isUpdate
        ? `work-packages/elements/list/${startDataCollection.id}`
        : `work-packages/elements/list`,
      method: isUpdate ? API_PATCH(reqData) : API_POST(reqData),
      onLoading: setIsLoading,
      onResponse: handleResponse,
    });
  }

  function handleResponse(res) {
    onClose();
    onCreate(res.data);
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Is required")),
  });

  return (
    <ExoForm
      fields={fields}
      onCancle={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      startDataCollection={startDataCollection}
      validationSchema={validationSchema}
      header={
        isUpdate
          ? t("Update Work Package Element")
          : t("Add Work Package Element")
      }
      sx={sx}
      className={className}
    />
  );
};
