import { useTranslation } from "react-i18next";
import { ExoVariants } from "../../ExoVariants";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { WPEToDoListForm } from "./types/WPEToDoListForm";
import { WPEFilesForm } from "./types/WPEFilesForm";

export const WPEForm = ({
  workPackage,
  startDataCollection,
  onCreate,
  onClose,
  sx,
  className,
}) => {
  const { t } = useTranslation();

  const variants = [
    {
      label: t("Files"),
      Icon: InsertDriveFileIcon,
      content: (
        <WPEFilesForm
          onCreate={onCreate}
          onClose={onClose}
          workPackage={workPackage}
          startDataCollection={startDataCollection}
          sx={sx}
          className={className}
        />
      ),
    },
    {
      label: t("To-Do List"),
      Icon: InsertDriveFileIcon,
      content: (
        <WPEToDoListForm
          onCreate={onCreate}
          onClose={onClose}
          workPackage={workPackage}
          startDataCollection={startDataCollection}
          sx={sx}
          className={className}
        />
      ),
    },
  ];
  return <ExoVariants variants={variants} />;
};
