import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import { tokens } from '../../global/theme/tokens';
import ExoAvatar from '../exo/ExoAvatar';

export const AiProcessing = ({ active, className, label, style }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (!active) return;

  const width = 0.8;
  const blurStrength = 6;

  return (
    <Box
      className={'flex justify-center items-center ' + className}
      sx={{
        pointerEvents: 'none',
      }}
    >
      <Box
        className="px-3 py-2 backdrop-blur-lg flex flex-col gap-2 items-center rounded-lg"
        sx={{ bgcolor: colors.glass }}
      >
        <ExoAvatar
          bgcolor={true}
          picture="/assets/uploads/sia-avatar.webp"
          size={'120px'}
          className=" shadow-sm"
        />
        <Box className="flex gap-2 items-end justify-center">
          <Typography
            variant="h2"
            fontWeight={900}
            className="rainbow"
            style={{
              backgroundClip: 'text',
              color: 'transparent',
            }}
          >
            SIA
          </Typography>
          <Typography variant="h4" className="pb-[4px]">
            {label || t('is thinking')}.
          </Typography>
        </Box>
      </Box>
      <div
        className="w-full h-full absolute top-0 left-0"
        style={{
          pointerEvents: 'none',
          filter: `blur(${blurStrength}px)`,
        }}
      >
        <div
          className={'w-full h-full rainbow'}
          style={{
            pointerEvents: 'none',
            clipPath: `polygon(0% 0%, 0% 100%, ${width}% 100%, ${width}% ${width}%, ${
              100 - width
            }% ${width}%, ${100 - width}% ${100 - width}%, ${width}% ${
              100 - width
            }%, ${width}% 100%, 100% 100%, 100% 0%)`,
          }}
        />
      </div>
    </Box>
  );
};
