import { Box, Button, useTheme, Typography } from '@mui/material';
import { tokens } from '../../../global/theme/tokens';
import { renderHours } from '../worktime';
import SmallProgressBar from '../../../components/special/SmallProgressBar';
import { useTimeTracking } from '../../../components/time-tracking/AppTimeProvider';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import MediaPreview from '../../../components/exo/explorer/MediaPreview';

export const WorkPackageWrapper = ({ workPackage, project, onDeselect }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { clockIn } = useTimeTracking();

  const size = '50px';
  const percent =
    workPackage.totalTimeWorkedSeconds / workPackage.estimatedTime;

  function handleClockIn() {
    onDeselect();
    clockIn(project, workPackage);
  }

  return (
    <Box
      className=" rounded-lg"
      sx={{
        borderColor: colors.card,
        borderWidth: '1px',
        ':hover': {
          borderColor: colors.success,
          backgroundColor: colors.success + '30',
          cursor: 'pointer',
        },
      }}
    >
      <Box
        onClick={handleClockIn}
        className="flex flex-row gap-4 rounded-lg w-full items-center justify-center"
        sx={{ padding: '20px 10px', bgcolor: colors.card }}
      >
        {workPackage.icon && (
          <MediaPreview src={workPackage.icon.temporaryUrl} size="140px" />
        )}
        <Box className="flex flex-col w-full items-center">
          {workPackage.estimatedTime ? (
            <SmallProgressBar size={size} percent={percent} />
          ) : (
            <AllInclusiveIcon sx={{ fontSize: size }} />
          )}
          <Box className="flex flex-col">
            <Typography variant="h3" className="monospace pt-2">
              {renderHours(workPackage.totalTimeWorkedSeconds / 60)} h
            </Typography>
            <Typography variant="h5">{workPackage.title}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
