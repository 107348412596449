import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import StyledTextContainer from '../../components/preview/StyledTextContainer';
import PreviewPositions from '../../components/preview/PreviewPositions';
import DocumentHead from './DocumentHead';

const SignOffer = ({ document, ownCompany }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="flex flex-col gap-4">
      <DocumentHead document={document} ownCompany={ownCompany} />
      <PreviewPositions
        positions={document.positions}
        sx={{
          th: {
            backgroundColor: colors.selected,
          },
          'tr:nth-child(even):not(.sum-row) td': {
            backgroundColor: colors.card,
          },
          '.sum-row td': {
            backgroundColor: colors.card,
          },
        }}
      />
      <Box
        sx={{
          padding: '5px!important',
        }}
      >
        {document.notice}
      </Box>
    </Box>
  );
};

export default SignOffer;
