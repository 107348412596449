import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import useFetch from "../../../api/useFetch";
import { ExpandAccounting } from "./ExpandAccounting";
export const ShowAccounting = () => {
  const { id } = useParams();

  const { apiData } = useFetch(`accounting-exports/${id}`);

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      {apiData && <ExpandAccounting row={apiData?.data} />}
    </Box>
  );
};
