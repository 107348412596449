import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormHelperText from "../FormHelperText";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../../api/api";
import "moment/locale/en-gb";

const moment = require("moment");

const TypeDateRange = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [format, setFormat] = useState("YYYY-MM-DD");
  const config = useConfig();

  useEffect(() => {
    if (!config) return;
    setFormat(config.general.dateOutputFormat);
  }, [config]);

  useEffect(() => {
    // init moment date objects
    if (field.value !== "today") {
      if (dataCollection[`${field.key}End`] && !endDate) {
        setEndDate(moment(dataCollection[`${field.key}End`], "YYYY-MM-DD"));
      }
      if (dataCollection[`${field.key}Start`] && !startDate) {
        setStartDate(moment(dataCollection[`${field.key}Start`], "YYYY-MM-DD"));
      }
    }
  }, [format, dataCollection]);

  function handleChangeStart(value) {
    setStartDate(value);
    updateValidationOnChange([`${field.key}Start`], value.format("YYYY-MM-DD"));
  }
  function handleChangeEnd(value) {
    setEndDate(value);
    updateValidationOnChange([`${field.key}End`], value.format("YYYY-MM-DD"));
  }

  // Set the locale to one that starts the week on Monday
  useEffect(() => {
    moment.locale("en-gb"); // "en-gb" uses Monday as the first day of the week
  }, []);
  return (
    <Box>
      <Box className="flex flex-row gap-2 w-full items-center">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            className="w-full"
            onChange={handleChangeStart}
            value={startDate}
            format={format}
            label={`${field.label} ${t("Start")}`}
          />
          -
          <DatePicker
            className="w-full"
            onChange={handleChangeEnd}
            value={endDate}
            format={format}
            label={`${field.label} ${t("End")}`}
          />
        </LocalizationProvider>
      </Box>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </Box>
  );
};

export default TypeDateRange;
