import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Tooltip,
  useTheme,
  Typography,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExoDialog from '../ExoDialog';
import AddMembers from '../../special/AddMembers';
import { useState } from 'react';
import { API_POST, DBRequest, useConfig } from '../../../api/api';
import { getInitials } from '../ExoAvatar';
import { tokens } from '../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
export const ProjectUserStack = ({
  workPackage,
  users,
  className,
  onChange,
  editmode,
  projectId,
  api,
  changeApi,
  label,
  displayCount = 4,
  noLabel,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const config = useConfig();
  const [open, setOpen] = useState(false);

  function handleAddMember(ids, newMembers) {
    const data = {
      relations: ids,
      mode: 'sync',
      relationType: 'users',
    };
    DBRequest({
      config,
      path: changeApi || `work-packages/${workPackage.id}/members`,
      method: API_POST(data),
      onResponse: (res) => handleResponse(res, newMembers),
    });
  }

  function handleResponse(data, newMembers) {
    setOpen(false);
    onChange(data.data, newMembers);
  }

  return (
    <Box
      sx={{
        '& .MuiAvatar-root': {
          borderColor: colors.selected,
          borderWidth: '1px',
          backgroundColor: colors.glassSolid,
          color: colors.grey[900],
        },
      }}
    >
      {!noLabel && (
        <Typography variant="h6" className="uppercase pt-2">
          {label || t('Member')}
        </Typography>
      )}
      {users.length ? (
        <AvatarGroup
          className={`${className} ${editmode ? 'cursor-pointer' : ''}`}
          total={editmode ? users.length + 1 : users.length}
          onClick={() => setOpen(true)}
          sx={{
            '& .MuiAvatar-root': {
              borderColor: colors.selected,
              borderWidth: '1px',
              backgroundColor: colors.glassSolid,
              color: colors.grey[900],
            },
          }}
        >
          {users.map(
            (user, index) =>
              index < displayCount && (
                <Tooltip
                  title={`${user.firstName} ${user.lastName}`}
                  key={user.id}
                >
                  <Avatar
                    alt={`${user.firstName} ${user.lastName}`}
                    src={user.picture && user.picture.thumbnailUrl}
                    size="40px"
                  >
                    {getInitials(`${user.firstName} ${user.lastName}`)}
                  </Avatar>
                </Tooltip>
              )
          )}
          {editmode && (
            <Tooltip title={t(`Edit Team`)}>
              <Avatar size="40px">
                <GroupAddIcon />
              </Avatar>
            </Tooltip>
          )}
        </AvatarGroup>
      ) : (
        <Box className="flex flex-row-reverse">
          {editmode ? (
            <IconButton onClick={() => setOpen(true)}>
              <PersonAddIcon />
            </IconButton>
          ) : (
            <Tooltip title={t('Not Assigned')}>
              <Avatar size="40px">
                <NoAccountsIcon />
              </Avatar>
            </Tooltip>
          )}
        </Box>
      )}
      <ExoDialog open={open && editmode} onClose={() => setOpen(false)}>
        <AddMembers
          api={api || `projects/${projectId}/team`}
          className="mx-auto"
          onClose={() => setOpen(false)}
          members={users || []}
          onSubmit={handleAddMember}
        />
      </ExoDialog>
    </Box>
  );
};
