import { useTranslation } from "react-i18next";
import useFetch from "../../../api/useFetch";
import DateLabel from "../../../components/label/DateLabel";
import { Box } from "@mui/material";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../../components/exo/ExoTableToolbar";
import ExoTable from "../../../components/exo/ExoTable";
import { useState } from "react";
import { ExoTableUser } from "../../../components/exo/ExoTableUser";
import AddIcon from "@mui/icons-material/Add";
import { UploadPayroll } from "./UploadPayroll";
import ExoDialog from "../../../components/exo/ExoDialog";
import MoneyLabel from "../../../components/label/MoneyLabel";
import { ExpandPayroll } from "./ExpandPayroll";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import { updateOrCreate } from "../../../components/special/updateOrCreate";
import { useNavigate } from "react-router-dom";

const PayrollsTab = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { apiData, setApiData, isLoading } = useFetch("payrolls");

  const [open, setOpen] = useState(false);

  function handleNewElement(newElement) {
    setOpen(false);
    setApiData(updateOrCreate(apiData, newElement.data.data));
  }

  const Columns = [
    {
      header: <PriceCheckIcon />, // header of column
      key: "transaction", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "33px", // width of column
      cell: ({ row }) => (
        <Box>
          {" "}
          {row.transactions && row.transactions.length > 0 && (
            <PriceCheckIcon />
          )}
        </Box>
      ),
    },
    {
      header: <RequestPageIcon />, // header of column
      key: "file", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "33px", // width of column
      cell: ({ row }) => <Box>{row.file && <RequestPageIcon />}</Box>,
    },
    {
      header: t("Employee"), // header of column
      key: "user", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <ExoTableUser user={row.user} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) => navigate(`/expenses/payrolls/${row.id}`),
    },
    {
      header: t("Monat"), // header of column
      key: "endDate", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel format="MM/YYYY" date={row.endDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Salary"), // header of column
      key: "startDate", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "150px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Box className="monospace">
          <MoneyLabel money={row.amount} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  const [edit, setEdit] = useState(null);

  function handleEdit(data) {
    setEdit(data);
    setOpen(true);
  }

  function handleClose() {
    setEdit(null);
    setOpen(false);
  }
  return (
    <>
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New payroll")}
          icon={<AddIcon />}
          onClick={() => setOpen(true)}
        />
      </ExoTableToolbar>
      <ExoTable
        extendElement={({ row, onDelete, onCreate }) => (
          <ExpandPayroll
            row={row}
            onDelete={onDelete}
            onCreate={onCreate}
            onEdit={handleEdit}
          />
        )}
        data={apiData}
        isLoading={isLoading}
        columns={Columns}
      />
      <ExoDialog open={open} onClose={handleClose}>
        <UploadPayroll
          payroll={edit}
          onResponse={handleNewElement}
          onClose={handleClose}
        />
      </ExoDialog>
    </>
  );
};
export default PayrollsTab;
