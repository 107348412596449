import { Box, Button, Typography } from "@mui/material";
import CardGrid from "../../../components/cards/layout/CardGrid";
import {
  requestTypesIcons,
  requestTypesStrings,
} from "../../../components/special/requestTypes";
import CardContainer from "../../../components/cards/layout/CardContainer";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../global/theme/tokens";
import { API_GET, API_PATCH, DBRequest, useConfig } from "../../../api/api";
import Feedback from "../../../components/special/Feedback";
import { useEffect, useState } from "react";
import ExoWorkday from "../../../components/exo/ExoWorkday";
import DateTimeLabel from "../../../components/label/DateTimeLabel";
import { useTranslation } from "react-i18next";
import RenderHTML from "../../../components/preview/RenderHTML";
import moment from "moment";

const ExtendTimeCorrection = ({ request, onUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();
  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  const [showRequest, setShowRequest] = useState(null);
  useEffect(() => {
    DBRequest({
      config,
      path: `employeerequests/${request.id}`,
      method: API_GET,
      onResponse: setShowRequest,
    });
  }, [request, config]);

  return (
    <>
      <CardGrid>
        <CardContainer span={5} className="">
          <Typography
            variant="h3"
            className="flex items-center justify-center gap-2"
          >
            {requestTypesIcons[request.type]}
            {t(requestTypesStrings[request.type])}
          </Typography>
          <Typography variant="h6">
            <DateTimeLabel dateTime={request.createdAt} />
          </Typography>
        </CardContainer>
        <CardContainer span={99} rows={2}>
          <Box className="flex w-full justify-between">
            {showRequest && (
              <ExoWorkday
                res={2}
                resHeight={15}
                corrections={showRequest.data.corrections}
                customColumnLabel
                noInfo
              />
            )}
          </Box>
        </CardContainer>
      </CardGrid>

      <Feedback setState={setAlertState} state={alertState} />
    </>
  );
};
export default ExtendTimeCorrection;
