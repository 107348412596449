import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Button,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../api/useFetch';
import { tokens } from '../../../global/theme/tokens';
import ExoAlert from '../../exo/ExoAlert';
import ExoDialog from '../../exo/ExoDialog';
import MoneyLabel from '../../label/MoneyLabel';
import LabeledBox from '../../special/LabeledBox';
import { DBRequest, useConfig } from '../../../api/api';
import CheckIcon from '@mui/icons-material/Check';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const TypeAccessFeatures = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const config = useConfig();

  const [apiData, setApiData] = useState(null);

  const [aboSum, setAboSum] = useState(null);
  const [aboDuration, setAboDuration] = useState(
    dataCollection.aboDuration || 'annually'
  );

  useEffect(() => {
    DBRequest({ config, path: 'access-features', onResponse: handleResponse });
  }, [config]);

  function handleResponse(res) {
    if (dataCollection[field.key]) {
      // add booked flag
      res.data.forEach((element) => {
        var foundFeature = dataCollection[field.key].find(
          (obj) => obj.id === element.id
        );
        element.isBooked = Boolean(foundFeature);

        // add a free seat if the element is already booked
        const seatCorrection = Boolean(foundFeature) ? 1 : 0;
        element.availableSeats =
          element.bookedSeatsCount - element.usedSeatsCount + seatCorrection;
      });
    } else {
      res.data.forEach((element) => {
        // add a free seat if the element is already booked
        element.availableSeats =
          element.bookedSeatsCount - element.usedSeatsCount;
      });
    }
    setApiData(res);
  }
  useEffect(() => {
    if (apiData && dataCollection[field.key]) {
      updateFeatureSelection(dataCollection[field.key], apiData.data);
    }
  }, [apiData]);

  function updateFeatureSelection(oldFeatures, featuresList) {
    // Create a map from featuresList for quick lookup
    const featuresMap = new Map(
      featuresList.map((feature) => [feature.id, feature])
    );

    // Update oldFeatures based on featuresList
    const updatedFeatures = oldFeatures.map((feature) =>
      featuresMap.has(feature.id)
        ? { ...feature, ...featuresMap.get(feature.id) }
        : feature
    );

    updateValidationOnChange(
      field.key,
      { [field.key]: updatedFeatures, aboDuration },
      true
    );
    calcSum(updatedFeatures);
  }

  function calcSum(featuresToCalc) {
    let biannually = 0;
    let annually = 0;
    let monthly = 0;

    featuresToCalc.forEach((element) => {
      if (element.availableSeats <= 0) {
        const priceBiannualy =
          element.prices.biannually.discount?.price ||
          element.prices.biannually.price;
        biannually += parseFloat(priceBiannualy);

        const priceAnnually =
          element.prices.annually.discount?.price ||
          element.prices.annually.price;
        annually += parseFloat(priceAnnually);

        const priceMonthly =
          element.prices.monthly.discount?.price ||
          element.prices.monthly.price;
        monthly += parseFloat(priceMonthly);
      }
    });
    setAboSum({ biannually, annually, monthly });
  }

  const [open, setOpen] = useState(false);

  function handleChange(e) {
    updateValidationOnChange(field.key, { [field.key]: e, aboDuration }, true);
  }

  const billingPeriodMap = {
    biannually: t('Biennial billing'),
    annually: t('Annual billing'),
    monthly: t('Monthly billing'),
  };

  function handleSaveMoneyClick(e) {
    setAboDuration('biannually');
    e.stopPropagation();
  }

  function handleAboDurationChange(newDuration) {
    setAboDuration(newDuration);
    updateValidationOnChange('aboDuration', newDuration);
  }

  return (
    <>
      <LabeledBox
        label={field.label}
        onClick={() => setOpen(true)}
        className="flex flex-col"
      >
        {aboSum && (
          <Box>
            <Box>
              <Box className="flex gap-2 items-end justify-between">
                <Box>
                  <Typography variant="h4" className="monospace">
                    <MoneyLabel money={aboSum[aboDuration]} />
                  </Typography>
                  <Typography variant="h6">{t('per month')}</Typography>
                </Box>
                {aboDuration !== 'biannually' &&
                  aboSum[aboDuration] - aboSum.biannually > 0 && (
                    <Button
                      onClick={handleSaveMoneyClick}
                      color="info"
                      variant="outlined"
                      className="flex gap-2"
                    >
                      <img src="/assets/icons/discount.webp" className="w-10" />
                      <Box className="flex flex-col">
                        <Typography
                          variant="h4"
                          className="monospace"
                          color="info"
                        >
                          {t('save now') + ' '}
                          <b>
                            <MoneyLabel
                              money={
                                (aboSum[aboDuration] - aboSum.biannually) *
                                aboDurationMap.biannually
                              }
                            />
                          </b>
                        </Typography>
                        <Typography variant="h6">
                          {t('with biennial payment')}
                        </Typography>
                      </Box>
                    </Button>
                  )}
              </Box>
              <Box className="py-2">
                <Divider />
              </Box>

              <Typography variant="h6" className="pb-2">
                {billingPeriodMap[aboDuration]}
              </Typography>
            </Box>
            {dataCollection[field.key] &&
              dataCollection[field.key].map((feature) => (
                <AccessFeatureLabel
                  key={feature.key}
                  feature={feature}
                  aboDuration={aboDuration}
                />
              ))}
          </Box>
        )}
      </LabeledBox>

      <ExoDialog open={open} limitWidth onClose={() => setOpen(false)}>
        <AccessFeatureList
          apiData={apiData}
          onChange={handleChange}
          selectedFeatures={dataCollection[field.key]}
          onClose={() => setOpen(false)}
          setAboSum={setAboSum}
          aboSum={aboSum}
          setAboDuration={handleAboDurationChange}
          aboDuration={aboDuration}
        />
      </ExoDialog>
    </>
  );
};

const AccessFeatureList = ({
  apiData,
  onChange,
  selectedFeatures,
  onClose,
  setAboSum,
  aboSum,
  aboDuration,
  setAboDuration,
}) => {
  const { t } = useTranslation();

  function findIfSelected(feature) {
    if (!selectedFeatures) return false;
    const element = selectedFeatures.find((obj) => obj.key === feature.key);
    return element;
  }

  function updateSelectedFeatures(feature) {
    // its the first feature
    if (!selectedFeatures)
      return checkRequirements([feature], aboDuration, feature);
    // its not the first feature
    const index = selectedFeatures.findIndex((obj) => obj.key === feature.key);
    if (index === -1) {
      // add feature
      checkRequirements([...selectedFeatures, feature], aboDuration, feature);
    } else {
      //remove feature
      const newSelectedFeatures = selectedFeatures.filter(
        (obj) => obj.key !== feature.key
      );

      // check if required features are missing after removing
      const featureWithMissingRequirements =
        findElementsWithMissingRequirements(newSelectedFeatures);

      if (!featureWithMissingRequirements.length)
        return handleUpdatedSelectedFeatures(
          newSelectedFeatures,
          aboDuration,
          feature
        );

      setFeaturesToSubmit({
        type: 'info',
        submitLabel: t('Remove this function'),
        cancleLabel: t('Do not deselect function'),
        message: t(
          'The function could not be deselected because it is required by another function:'
        ),
        features: newSelectedFeatures,
        duration: aboDuration,
        missingFeatures: featureWithMissingRequirements,
        onSubmit: () =>
          updateSelectedFeatures(featureWithMissingRequirements[0]),
      });
    }
  }

  // Function to find elements with missing requirements
  const findElementsWithMissingRequirements = (newSelection) => {
    return newSelection.filter((element) => {
      // Check if all required elements are in previous selection
      if (element.requirements)
        return element.requirements.some(
          (requirement) =>
            !newSelection.some(
              (prevElement) => prevElement.id === requirement.id
            )
        );
    });
  };

  const [featuresToSubmit, setFeaturesToSubmit] = useState(null);
  function checkRequirements(newSelectedFeatures, duration, newFeature) {
    const { requirements } = newFeature;

    // select if no requirements
    if (!requirements || !requirements.length)
      return handleUpdatedSelectedFeatures(
        newSelectedFeatures,
        duration,
        newFeature
      );

    // check if there are some requirements that are not selected yet
    const missingElements = requirements.filter(
      (required) =>
        !newSelectedFeatures.some((selected) => selected.id === required.id)
    );

    const missingFeatures = findMatchingEntries(missingElements, apiData.data);

    if (missingElements.length) {
      setFeaturesToSubmit({
        type: 'info',
        submitLabel: t('Select missing functions'),
        cancleLabel: t('Do not select function'),
        message: t(
          'To be able to use this function, the following functions are also required:'
        ),
        features: [...newSelectedFeatures, ...missingFeatures],
        duration,
        missingFeatures: missingFeatures,
        onSubmit: handleUpdatedSelectedFeatures,
      });
    } else {
      handleUpdatedSelectedFeatures(newSelectedFeatures, duration, newFeature);
    }
  }

  // Function to find entries in arrayB where the id exists in arrayA
  const findMatchingEntries = (arrayA, arrayB) => {
    const idsInA = arrayA.map((a) => a.id); // Get the ids from arrayA
    return arrayB.filter((b) => idsInA.includes(b.id)); // Filter arrayB based on arrayA's ids
  };

  function handleUpdatedSelectedFeatures(newSelectedFeatures, duration) {
    setFeaturesToSubmit(null);
    let biannually = 0;
    let annually = 0;
    let monthly = 0;

    newSelectedFeatures.forEach((element) => {
      if (element.availableSeats <= 0) {
        const priceBiannualy =
          element.prices.biannually.discount?.price ||
          element.prices.biannually.price;
        biannually += parseFloat(priceBiannualy);

        const priceAnnually =
          element.prices.annually.discount?.price ||
          element.prices.annually.price;
        annually += parseFloat(priceAnnually);

        const priceMonthly =
          element.prices.monthly.discount?.price ||
          element.prices.monthly.price;
        monthly += parseFloat(priceMonthly);
      }
    });
    setAboSum({ biannually, annually, monthly });
    onChange(newSelectedFeatures);
  }

  function handleAboDurationChange(e) {
    setAboDuration(e.target.value);
  }

  return (
    <Box className="flex flex-col gap-3 h-full overflow-y-auto px-1">
      <Box className="flex justify-between backdrop-blur-lg sticky top-0 z-10 px-2 py-1">
        <Box>
          <Typography variant="h6">{t('Accounting period')}</Typography>
          <ToggleButtonGroup
            size="small"
            value={aboDuration}
            exclusive
            onChange={handleAboDurationChange}
            aria-label="Project Status"
            className="pb-2 backdrop-blur-lg"
          >
            <ToggleButton value="monthly">{t('Monthly')}</ToggleButton>
            <ToggleButton value="annually">{t('Annually')}</ToggleButton>
            <ToggleButton value="biannually">{t('Biannually')}</ToggleButton>
          </ToggleButtonGroup>
          <Box className="flex flex-col">
            <Typography variant="h5" className="monospace">
              <MoneyLabel
                money={
                  aboSum ? aboSum[aboDuration] * aboDurationMap[aboDuration] : 0
                }
              />
            </Typography>
            <Typography variant="h6">
              {t('Invoicing for the period')}
            </Typography>
          </Box>
        </Box>

        <Box className="flex flex-col gap-2">
          <Box className="flex flex-col">
            <Typography variant="h2" className="monospace" fontWeight={600}>
              <MoneyLabel money={aboSum ? aboSum[aboDuration] : 0} />
            </Typography>
            <Typography variant="h6">{t('per month')}</Typography>
          </Box>
          <Button variant="contained" color="success" onClick={onClose}>
            {t('Continue')}
          </Button>
        </Box>
      </Box>

      {apiData &&
        apiData.data.map((feature) => (
          <AccessFeature
            key={feature.key}
            onClick={updateSelectedFeatures}
            feature={feature}
            selected={findIfSelected(feature)}
            price={feature.prices[aboDuration].price}
            discount={feature.prices[aboDuration].discount}
          />
        ))}
      <ExoAlert
        onSubmit={() =>
          featuresToSubmit.onSubmit(
            featuresToSubmit.features,
            featuresToSubmit.duration
          )
        }
        open={Boolean(featuresToSubmit)}
        alert={featuresToSubmit || {}}
        onClose={() => setFeaturesToSubmit(null)}
      >
        <Typography>{featuresToSubmit && featuresToSubmit.message}</Typography>
        {featuresToSubmit &&
          featuresToSubmit.missingFeatures.map((feature, index) => (
            <Typography key={feature.key} className="py-1">
              <NavigateNextIcon fontSize="12px" className=" mr-2" />
              {t(feature.label)}
            </Typography>
          ))}
      </ExoAlert>
    </Box>
  );
};

const AccessFeature = ({ feature, onClick, selected, price, discount }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();

  const { label } = feature;

  const availableSeats = selected
    ? feature.availableSeats - 1
    : feature.availableSeats;

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => onClick(feature)}
      sx={{
        bgcolor: selected ? colors.success + '30' : '',
        borderColor: selected ? colors.success : colors.card,
        borderWidth: '1px',
        borderStyle: 'solid',
      }}
    >
      <Box className="flex flex-col w-full">
        <Box className="flex w-full justify-between">
          <Box className="flex justify-start items-center gap-2">
            {selected ? (
              <CheckCircleIcon color="success" />
            ) : (
              <AddCircleOutlineIcon />
            )}
            <Typography fontWeight={500}>{t(label)}</Typography>
          </Box>
          <Box className="flex flex-col items-end">
            {feature?.isBooked ? (
              <Typography fontWeight={700}> {t('attached')}</Typography>
            ) : feature.availableSeats ? (
              <Typography fontWeight={700}>{t('existing licenses')}</Typography>
            ) : discount ? (
              <Box className="flex gap-2 items-center">
                <Typography
                  className="monospace flex gap-2 items-center absolute bottom-1 right-1 rounded-md px-2"
                  bgcolor={colors.success + '30'}
                  sx={{
                    border: `1px solid ${colors.success}`,
                  }}
                >
                  -
                  {(1 - parseFloat(discount.price) / parseFloat(price)).toFixed(
                    2
                  ) * 100}
                  % <img src="/assets/icons/discount.webp" className="h-7" />
                </Typography>
                <Typography className="monospace line-through" variant="h6">
                  <MoneyLabel money={price} />
                </Typography>

                <Typography className="monospace" fontWeight={700}>
                  <MoneyLabel money={discount.price} />
                </Typography>
              </Box>
            ) : (
              <Typography className="monospace" fontWeight={700}>
                <MoneyLabel money={price} />
              </Typography>
            )}

            {Boolean(availableSeats > 0) && (
              <Typography fontSize={11}>
                <b>{availableSeats}</b>
                {` ${t('Available licenses')}`}
              </Typography>
            )}
          </Box>
        </Box>
        <Typography
          textAlign={'left'}
          className="opacity-70 pr-[80px]"
          fontSize={12}
        >
          {t(feature.description)}
        </Typography>
      </Box>
    </Button>
  );
};

const AccessFeatureLabel = ({ feature, aboDuration }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const needsPurchase = feature.availableSeats <= 0;

  const price = parseFloat(feature.prices[aboDuration].price);
  const priceDiscount = parseFloat(feature.prices[aboDuration].discount?.price);

  return (
    <Box className="flex items-center gap-6 pl-4 min-h-[30px]">
      <Typography fontWeight={600}>{t(feature.label)}</Typography>
      <NavigateNextIcon fontSize="12px" />
      <Typography
        color={needsPurchase ? colors.warning : colors.success}
        className="flex items-center gap-2"
        fontWeight={500}
      >
        {needsPurchase ? t('will be booked') : t('already booked')}
        {needsPurchase ? <ShoppingCartIcon /> : <CheckIcon />}
      </Typography>
      {needsPurchase && (
        <>
          <NavigateNextIcon fontSize="12px" />
          {priceDiscount ? (
            <Box className="flex gap-2 items-center">
              <Typography className="monospace line-through" variant="h6">
                <MoneyLabel money={price} />
              </Typography>

              <Typography className="monospace pr-5" fontWeight={700}>
                <MoneyLabel money={priceDiscount} />
              </Typography>
              <Typography
                className="monospace flex gap-2 items-center rounded-md px-2"
                bgcolor={colors.success + '30'}
                sx={{
                  border: `1px solid ${colors.success}`,
                }}
              >
                -{(1 - priceDiscount / price).toFixed(2) * 100}
                % <img src="/assets/icons/discount.webp" className="h-7" />
              </Typography>
            </Box>
          ) : (
            <Typography>
              <MoneyLabel money={price} />
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export const aboDurationMap = {
  biannually: 24,
  annually: 12,
  monthly: 1,
};

export default TypeAccessFeatures;
